import {
  ListItemText,
  Typography,
  ListItem,
  useTheme,
  Divider,
  Button,
  Stack,
  Grid,
  Box,
} from "@mui/material";
import DragIndicatorOutlinedIcon from "@mui/icons-material/DragIndicatorOutlined";
import { endOfMonth, addMonths, subMonths, set, format } from "date-fns";
import ModeEditRoundedIcon from "@mui/icons-material/ModeEditRounded";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useSnackbar } from "notistack";

import DeleteConfirmationModal from "../../../components/Model/DeleteConfirmationModal";
import DNDListProvider, { DNDListItem } from "../../../components/DND/context";
import { setDataSetData, setDataSetList } from "../../../store/slices/board";
import DescriptionInput from "../../../components/Overlay/DescriptionInput";
import initialData, { getDefaultFilters } from "../../../Helper/data";
import SubscriptionBadge from "../../../components/SubscriptionBadge";
import CustomModal from "../../../components/Model/CustomModal";
import TitleInput from "../../../components/Overlay/TitleInput";
import useSubscriptions from "../../../hooks/useSubscriptions";
import HelpOverlay from "../../../components/HelpOverlay.js";
import DropDown from "../../../components/Overlay/DropDown";
import AddButton from "../../../components/AddButton";
import EndPoints from "../../../APICall/EndPoints";
import { Images, Color } from "../../../Helper";
import APICall from "../../../APICall";

const DataSetList = () => {
  let itemId = useRef(null);
  const theme = useTheme();
  let modalType = useRef("edit");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [isSubscriptionValid] = useSubscriptions();
  const navigate = useNavigate();
  const startDate = subMonths(set(new Date(), { date: 1 }), 1);
  const result = addMonths(new Date(startDate), 11);
  const endDate = endOfMonth(new Date(result));

  //redux
  const dataSource = useSelector((state) => state.globalSlice.dataSource);
  const dataSetList = useSelector((state) => state.boardSlice.dataSetList);

  //state
  const [open, setOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [datasetItem, setDataSetItem] = useState();
  const [error, setError] = useState("");
  const currencies = [
    {
      uuid: 1,
      code: "USD",
      name: t("currency_dollar"),
      symbol: "$",
    },
    {
      uuid: 2,
      code: "EUR",
      name: t("currency_euro"),
      symbol: "€",
    },
    {
      uuid: 3,
      code: "GBP",
      name: t("currency_pound"),
      symbol: "£",
    },
  ];
  const isIntegrationsConnected = useMemo(() => {
    if (dataSource && modalType.current === "edit") {
      const orgDS = dataSource?.filter(
        (ds) =>
          !ds?.internal_dataset &&
          ds?.datasets?.some((d) => d?.dataset === datasetItem?.uuid)
      );
      return orgDS?.length > 0;
    } else {
      return false;
    }
  }, [dataSource, datasetItem?.uuid]);

  //life cycle method
  useEffect(() => {
    getDataSetsApi();
    return () => {};
  }, []);

  //Api
  const deleteDataSetByIdApi = async (id) => {
    await APICall("delete", EndPoints.datasets + `${id}/`).then((response) => {
      if (response.status === 204 && response) {
        getDataSetsApi();
        enqueueSnackbar(t("dataset_Deleted_Successfully"), {
          variant: "success",
          autoHideDuration: 2000,
        });
      }
    });
  };

  const updateDataSetByID = async (id, obj) => {
    await APICall("patch", EndPoints.datasets + `${id}/`, obj).then(
      (response) => {
        if (response.status === 200 && response.data) {
        }
      }
    );
  };

  const addDataSetApi = async (obj) => {
    await APICall("post", EndPoints.datasets, obj).then((response) => {
      if (response.status === 201 && response.data) {
        enqueueSnackbar(t("New_dataset_Added_Successfully"), {
          variant: "success",
          autoHideDuration: 2000,
        });
        let array = [...dataSetList];
        array.push(response.data);
        dispatch(setDataSetList(array));
      }
    });
  };

  const getDataSetsApi = async () => {
    await APICall("get", EndPoints.datasets).then((response) => {
      if (response.status === 200 && response.data) {
        dispatch(setDataSetList(response.data.results));
      }
    });
  };

  const getDataSetByIdApi = async (id) => {
    await APICall("get", EndPoints.datasets + `${id}/`).then((response) => {
      if (response.status === 200 && response.data) {
        let data = response.data;
        if (!data.start_date && !data.end_date) {
          data = {
            ...data,
            start_date: format(
              set(new Date(startDate), { date: 1 }),
              "yyyy-MM-dd"
            ),
            end_date: format(new Date(endDate), "yyyy-MM-dd"),
          };
        }
        dispatch(setDataSetData(data));
        navigate(`/${initialData?.path?.organization}/${data?.uuid}/table`);
      }
    });
  };

  const batchUpdateDataSets = async (payload) => {
    await APICall("put", EndPoints.datasets + "batch_update/", payload, {
      doNotCatchRespond: true,
    });
  };

  //function
  const onClickAddNewDataSet = () => {
    if (
      isSubscriptionValid({
        showMessage: true,
        type: "dataset",
      })
    ) {
      const filters = getDefaultFilters();
      modalType.current = "add";
      const position = dataSetList?.map((item) => item?.position);

      const obj = {
        title: "",
        position: `${dataSetList?.length > 0 ? Math.max(...position) + 1 : 1}`,
        description: "",
        initial_account_balance: 0.0,
        start_date: format(new Date(startDate), "yyyy-MM-dd"),
        end_date: format(new Date(endDate), "yyyy-MM-dd"),
        currency: "EUR",
        type: "agency",
        filters: filters,
      };
      setDataSetItem(obj);
      setOpen(true);
    }
  };

  const onClickDeleteDataSet = (e, item) => {
    e.stopPropagation();
    itemId.current = item?.uuid;
    setIsDeleteOpen(true);
  };

  const onClickEditDataSet = (e, item) => {
    e.stopPropagation();
    modalType.current = "edit";
    setDataSetItem(item);
    setOpen(true);
  };

  const onClickDataSet = (e, item) => {
    getDataSetByIdApi(item.uuid);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    if (error) setError(null);
    let obj = { ...datasetItem, [name]: value };
    setDataSetItem(obj);
  };

  const handleEditDescriptionChange = (e) => {
    let text = e.target.value;
    let obj = { ...datasetItem, description: text };
    setDataSetItem(obj);
  };

  const handleEditCurrencyChange = (e, value) => {
    if (error) setError(null);
    let obj = { ...datasetItem, currency: value?.code ?? null };
    setDataSetItem(obj);
  };

  const handleClose = () => {
    setOpen(false);
    setError(null);
  };

  const onCloseDelete = () => {
    setIsDeleteOpen(false);
  };

  const onOk = () => {
    setIsDeleteOpen(false);
    deleteDataSetByIdApi(itemId.current);
  };

  const onAdd = async () => {
    let errorText = "";
    if (!datasetItem?.title || datasetItem?.title.trim() === "") {
      errorText = `${t("title_empty_error")}`;
      setError({ ...error, title: errorText });
      return;
    }
    if (!datasetItem?.alias || datasetItem?.alias.trim() === "") {
      setDataSetItem((prev) => ({
        ...prev,
        alias: datasetItem?.title?.substring(0, 3),
      }));
    }
    if (
      datasetItem?.alias
        ? datasetItem?.alias?.trim()?.length !== 3
        : datasetItem?.title?.substring(0, 3).length !== 3
    ) {
      errorText = `${t("alias_3_letter")}`;
      setError({ ...error, alias: errorText });
      return;
    }
    if (!datasetItem?.currency) {
      errorText = `${t("select_currency")}`;
      setError({ ...error, currency: errorText });
      return;
    }
    let obj = datasetItem;
    if (!datasetItem?.alias) {
      obj = {
        ...datasetItem,
        alias: datasetItem?.title?.substring(0, 3),
      };
    }
    if (modalType.current === "edit") {
      await updateDataSetByID(obj?.uuid, obj);
      await getDataSetsApi();
    } else {
      addDataSetApi(obj);
    }
    setOpen(false);
  };

  const onChangeItems = ({ items }) => {
    dispatch(setDataSetList(items));
    batchUpdateDataSets(items);
  };

  //RenderFunction
  const editModal = () => {
    return (
      <CustomModal
        heading={t("dataset")}
        open={open}
        modalType={modalType.current}
        onAdd={onAdd}
        onClose={handleClose}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "40rem",
            backgroundColor: Color.white,
            p: "1.5rem",
          }}
        >
          <TitleInput
            name="title"
            value={datasetItem?.title || ""}
            onChange={handleEditChange}
            helperText={error?.title}
            error={Boolean(error?.title)}
            hideTitle
            label={"Title"}
            likeGoogle
            variant="filled"
          />
          <TitleInput
            name="alias"
            value={datasetItem?.alias || ""}
            onChange={handleEditChange}
            helperText={error?.alias}
            error={Boolean(error?.alias)}
            hideTitle
            label={t("Alias")}
            likeGoogle
            variant="filled"
            sx={{ mt: "2rem" }}
          />
          <DescriptionInput
            value={datasetItem?.description || ""}
            onChange={handleEditDescriptionChange}
            hideTitle
            rows={null}
            label={"Note (Optional)"}
            likeGoogle
            variant="filled"
            sx={{ mt: "2rem" }}
          />
          <DropDown
            value={
              currencies?.find((o1) => o1?.code === datasetItem?.currency) ??
              null
            }
            onChange={handleEditCurrencyChange}
            getOptionLabel={(option) => option?.code}
            renderOption={(option) => `${option?.symbol} ${option?.name}`}
            options={currencies}
            tooltip={t("Select_Currency")}
            helperText={error?.currency}
            error={Boolean(error?.currency)}
            disabled={isIntegrationsConnected}
            mb={0}
            hideTitle
            likeGoogle
            variant="filled"
            label={t("Currency")}
            sx={{ mt: "3rem" }}
          />
          {/* <Box sx={{ pl: "2.3rem", width: "92%", mt: "2rem" }}>
            <DropDown
              disableClearable
              value={
                initialData.datasetType?.find(
                  (o1) => o1?.value === datasetItem?.type
                ) ?? null
              }
              onChange={handleEditDataSetType}
              getOptionLabel={(option) => option?.display_name}
              renderOption={(option) => option?.display_name}
              options={initialData.datasetType}
              tooltip={t("select__dataset_type")}
              mb={0}
              hideTitle
              likeGoogle
              variant="filled"
              label={t("type")}
            />
          </Box> */}
        </Box>
      </CustomModal>
    );
  };

  return (
    <Box
      sx={{
        height: "100%",
        backgroundColor: Color.appThemeBg,
        position: "relative",
        ml: "90px",
      }}
    >
      <DeleteConfirmationModal
        open={isDeleteOpen}
        onClose={onCloseDelete}
        onOk={onOk}
        message={`${t("Are_you_sure_delete_this")} ${t("dataset")}?`}
        type="delete"
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: "80%",
          mt: "4rem",
        }}
      >
        <Typography
          variant="h5"
          color="color.slate.700"
          fontWeight={"fontWeightMediumBold"}
        >
          {t("datasets")}
        </Typography>
        <Stack
          direction="row"
          alignItems={"center"}
          sx={{
            position: "relative",
          }}
        >
          <SubscriptionBadge type="dataset" sx={{ mr: "1.25rem" }} />

          <AddButton
            className={"dataSetList-1-step"}
            tooltipLabel={t("Add_dataset")}
            label={t("Add_dataset")}
            onClick={onClickAddNewDataSet}
            isNKey
            doNotListenKey={open}
          />
          <HelpOverlay
            showRight
            hideHeader
            text={initialData?.path?.Organizations}
            path={initialData?.path?.organizations}
            image={Images.dataset_lists_insights}
            wrapperStyle={{
              position: "absolute",
              right: "-3.5rem",
            }}
          />
        </Stack>
      </Box>
      <Typography
        variant="subtitle2"
        color="color.description"
        fontWeight={"fontWeightMedium"}
        sx={{
          my: "0.5rem",
          mb: "1.5rem",
        }}
      >
        {t("datasets_description")}
      </Typography>

      <Divider
        sx={{
          width: "80%",
          mb: "1rem",
        }}
      />
      <Grid container elevation={1} sx={{ width: "80%" }}>
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            width: "70%",
            mb: "0.5rem",
          }}
        >
          <Grid item xs={4.5}>
            <Typography
              sx={{
                textAlign: "left",
                fontSize: "1rem",
                ml: "3rem",
              }}
              variant="h6"
              component="div"
            >
              {t("Title")}
            </Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography
              sx={{
                textAlign: "left",
                fontSize: "1rem",
              }}
              variant="h6"
              component="div"
            >
              {t("Description")}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              sx={{
                textAlign: "left",
                fontSize: "1rem",
              }}
              variant="h6"
              component="div"
            >
              {t("Alias")}
            </Typography>
          </Grid>
          <Grid item xs={2.5}>
            <Typography
              sx={{
                textAlign: "left",
                fontSize: "1rem",
              }}
              variant="h6"
              component="div"
            >
              {t("Currency")}
            </Typography>
          </Grid>
        </Box>
        <Stack
          sx={{
            width: "100%",
            maxHeight: "31.25rem",
            overflow: "auto",
            ...theme.thinScrollBar,
          }}
        >
          {dataSetList?.length > 0 ? (
            <DNDListProvider
              instanceID="datasets"
              items={dataSetList}
              setItems={onChangeItems}
            >
              {dataSetList?.map((item, index) => {
                return (
                  <ItemViewWrapper
                    key={item?.uuid}
                    item={item}
                    index={index}
                    onClickDataSet={(e) => onClickDataSet(e, item)}
                    onClickEditDataSet={(e) => onClickEditDataSet(e, item)}
                    onClickDeleteDataSet={(e) => onClickDeleteDataSet(e, item)}
                  />
                );
              })}
            </DNDListProvider>
          ) : (
            <Typography
              sx={{
                fontSize: "0.875rem",
                color: Color.grey,
              }}
            >
              {t("No_Data_Found")}
            </Typography>
          )}
        </Stack>
      </Grid>
      {open && editModal()}
    </Box>
  );
};
export default DataSetList;
const ItemViewWrapper = ({
  item,
  index,
  onClickDataSet,
  onClickEditDataSet,
  onClickDeleteDataSet,
}) => {
  const dragHandleRef = useRef(null);
  const elementRef = useRef(null);
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <DNDListItem
      elementRef={elementRef}
      dragHandleRef={dragHandleRef}
      item={item}
      index={index}
      itemView={
        <ListItem
          divider
          sx={{
            position: "relative",
            px: 0,
            backgroundColor: Color.white,
            "& .actionBtn": {
              backgroundColor: Color.white,
              borderRadius: 1,
              minWidth: "1.8rem",
              px: "4px",
              height: "1.8rem",
              marginLeft: "0.5rem",
              display: "none",
              color: Color.black,
              border: `1px solid ${Color.tailwind.slate[300]}`,
              "&: hover": {
                backgroundColor: Color.tailwind.slate[100],
              },
              "& .actionBtnIcon": {
                backgroundColor: "transparent",
                color: Color.black,
                fontSize: "1.2rem",
                margin: 0,
              },
            },
            "& .itemText": {
              flex: "none",
              "& span": {
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                fontSize: "0.9rem",
                fontWeight: 600,
                width: "100%",
                fontFamily: theme.typography.fontFamily,
              },
              textAlign: "left",
            },
            "&: hover": {
              backgroundColor: theme.palette.color.slate[50],
              "& #dnd-drag-handle": {
                visibility: "visible !important",
              },
              "& .actionBtn": {
                display: "flex",
              },
            },
          }}
        >
          <div
            ref={dragHandleRef}
            id={"dnd-drag-handle"}
            style={{
              visibility: "hidden",
              position: "absolute",
              cursor: "grab",
              left: "1.5%",
              top: "26%",
            }}
          >
            <DragIndicatorOutlinedIcon />
          </div>
          <Grid
            item
            xs={4.5}
            sx={{
              pl: "3rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            <ListItemText primary={item?.title} className={"itemText"} />
            <Button onClick={onClickDataSet} className={"actionBtn"}>
              {t("Access")}
            </Button>
            <Button onClick={onClickEditDataSet} className={"actionBtn"}>
              <ModeEditRoundedIcon className={"actionBtnIcon"} />
            </Button>
            <Button className={"actionBtn"} onClick={onClickDeleteDataSet}>
              <DeleteIcon className={"actionBtnIcon"} />
            </Button>
          </Grid>

          <Grid item xs={3}>
            <ListItemText
              className={"itemText"}
              sx={{
                pr: "2rem",
              }}
              primary={item?.description}
            />
          </Grid>
          <Grid item xs={2}>
            <ListItemText primary={item?.alias} className={"itemText"} />
          </Grid>
          <Grid item xs={2}>
            <ListItemText primary={item?.currency} className={"itemText"} />
          </Grid>
        </ListItem>
      }
    />
  );
};
