import {
  eachMonthOfInterval,
  startOfMonth,
  isSameMonth,
  endOfMonth,
  subMonths,
  addMonths,
  isValid,
  format,
} from "date-fns";
import { forwardRef, useImperativeHandle, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { v4 as v4uuid } from "uuid";
import _ from "underscore";

import {
  getStatisticsDataWithParams,
  getAllTransactionsByParams,
  deleteBatchTransactions,
  updateBatchTransactions,
  addBatchTransactions,
  buildUrlFromParams,
  getRecurDateArray,
  formatDateToLocal,
  getStaffQueryKey,
  sleep,
} from "../../Helper/data";
import {
  calculateLoanTenure,
  calculateEndDate,
  calculateEMI,
} from "../../Helper/functions";
import EnableInternalDSFunctions from "../Header/DatasetHeader/EnableInternalDSFunctions";
import RulesCommonView from "../../Pages/Settings/Rules/RulesCommonView";
import { setRecurring_rules } from "../../store/slices/global";
import { setLoading } from "../../store/slices/appmain";
import EndPoints from "../../APICall/EndPoints";
import initialData from "./../../Helper/data";
import { queryClient } from "../../App";
import { Constant } from "../../Helper";
import APICall from "../../APICall";
import store from "../../store";

const RecurringSeqFunctions = forwardRef((props, _ref) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const internalDsRef = useRef(null);
  const rulesRef = useRef(null);

  //redux
  const recurring_rules = useSelector(
    (state) => state.globalSlice?.recurring_rules
  );
  const selectionCategoriesByID = useSelector(
    (state) => state.categorySlice.selectionCategoriesByID
  );
  const stateByTitle = useSelector((state) => state.globalSlice?.stateByTitle);
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);

  useImperativeHandle(_ref, () => ({
    onSaveRecurrence,
  }));

  //api
  const addBatch = async (array, onCloseEditForm) => {
    dispatch(setLoading(true));
    await addBatchTransactions(array);
    resetQuery();
    dispatch(setLoading(false));
    if (onCloseEditForm) {
      onCloseEditForm();
    }
    enqueueSnackbar(t("Cards Added Successfully"));
  };

  const batchAddValueSets = async (recurring_uuid, value_sets) => {
    let newValueSet = value_sets;
    await APICall(
      "post",
      EndPoints.recurring_rules + `${recurring_uuid}/value_sets/batch_add/`,
      value_sets
    ).then(async (response) => {
      if (response.status === 201 && response.data) {
        newValueSet = response.data;
        const groupData = _.groupBy(response.data, "title");
        for (const o1 of value_sets) {
          if (o1?.items?.length > 0) {
            const uuid = groupData?.[o1?.title]?.[0]?.uuid;
            const valueItem = await batchAddValueSetsItems(
              recurring_uuid,
              uuid,
              o1.items
            );
            if (valueItem?.length > 0) {
              const index = newValueSet?.findIndex((o1) => o1.uuid === uuid);
              if (index > -1) {
                newValueSet[index].items = valueItem;
              }
            }
          }
        }
      }
    });
    return newValueSet;
  };

  const batchAddValueSetsItems = async (
    recurring_uuid,
    value_set_uuid,
    items
  ) => {
    let data = null;
    await APICall(
      "post",
      EndPoints.recurring_rules +
        `${recurring_uuid}/value_sets/${value_set_uuid}/items/batch_add/`,
      items
    ).then((response) => {
      if (response.status === 201 && response.data) {
        data = response.data;
      }
    });

    return data;
  };

  const batchUpdateValueSetsItems = async (
    recurring_uuid,
    value_set_uuid,
    items
  ) => {
    await APICall(
      "put",
      EndPoints.recurring_rules +
        `${recurring_uuid}/value_sets/${value_set_uuid}/items/batch_update/`,
      items
    ).then((response) => {
      if (response.status === 200 && response.data) {
      }
    });
  };

  const addRecurringRules = async (
    obj,
    old_obj,
    recurring_type,
    value_sets
  ) => {
    await APICall("post", EndPoints.recurring_rules, obj).then(
      async (response) => {
        if (response.status === 201 && response.data) {
          let data = [...recurring_rules, response.data];
          if (old_obj) {
            let index = recurring_rules?.findIndex(
              (o1) => o1.uuid === old_obj?.uuid
            );
            if (index > -1) {
              data[index] = old_obj;
            }
          }
          if (recurring_type === "employee") {
            const newValueSet = await batchAddValueSets(
              response.data.uuid,
              value_sets
            );

            if (newValueSet?.length > 0 && response?.data) {
              let data = [
                ...recurring_rules,
                {
                  ...response?.data,
                  value_sets: newValueSet,
                },
              ];
              dispatch(setRecurring_rules(data));
            }
          } else {
            dispatch(setRecurring_rules(data));
          }
        }
      }
    );
  };

  const addPausedDurations = async (recurring_rule, obj) => {
    await APICall(
      "post",
      `${EndPoints.recurring_rules}${recurring_rule}/pause_durations/batch_add/`,
      obj
    ).then((response) => {
      if (response.status === 201 && response.data) {
      }
    });
  };

  const addVariableList = async (recurring_rule, obj) => {
    await APICall(
      "post",
      `${EndPoints.recurring_rules}${recurring_rule}/interest_rates/batch_add/`,
      obj
    ).then((response) => {
      if (response.status === 201 && response.data) {
      }
    });
  };

  const updateRecurringRules = async (
    id,
    obj,
    update = true,
    recurring_type,
    value_sets
  ) => {
    await APICall("patch", EndPoints.recurring_rules + `${id}/`, obj).then(
      async (response) => {
        if (response.status === 200 && response.data) {
          if (update) {
            let data = [...recurring_rules];
            let index = recurring_rules?.findIndex((o1) => o1.uuid === id);
            if (index > -1) {
              data[index] = response.data;
            }
            dispatch(setRecurring_rules(data));
          }
          if (recurring_type === "employee" && value_sets) {
            for (const o1 of value_sets) {
              if (o1?.items?.length > 0) {
                await batchUpdateValueSetsItems(
                  response.data?.uuid,
                  o1?.uuid,
                  o1.items
                );
              }
            }
            let data = [...recurring_rules];
            let index = recurring_rules?.findIndex((o1) => o1.uuid === id);
            if (index > -1) {
              data[index] = { ...response.data, value_sets };
            }
            dispatch(setRecurring_rules(data));
          }
        }
      }
    );
  };

  const getContacts = async (params) => {
    let result = null;
    await APICall("get", EndPoints.customers + buildUrlFromParams(params)).then(
      (response) => {
        if (response.status === 200 && response.data) {
          result = response.data.results;
        }
      }
    );
    return result;
  };

  const addContactApi = async (obj) => {
    let uuid = null;
    await APICall("post", EndPoints.customers, obj, {
      doNotCatchRespond: true,
    }).then((response) => {
      if (response.status === 201 && response.data) {
        uuid = response.data?.uuid;
      } else {
        if (response.data?.name?.[0]) {
          enqueueSnackbar(t("info_staff_already_existing_name"), {
            variant: "info",
            autoHideDuration: 5000,
          });
        }
      }
    });
    return uuid;
  };

  const updateContactApi = async (_uuid, obj) => {
    let uuid = null;
    await APICall("patch", EndPoints.customers + `${_uuid}/`, obj, {
      doNotCatchRespond: true,
    }).then((response) => {
      if (response.status === 200 && response.data) {
        uuid = response.data?.uuid;
      } else {
        if (response.data?.name?.[0]) {
          enqueueSnackbar(t("info_staff_already_existing_name"), {
            variant: "info",
            autoHideDuration: 5000,
          });
        }
      }
    });
    return uuid;
  };

  const updateCardByID = async (id, obj, payload) => {
    await APICall("patch", EndPoints.transactions + `${id}/`, obj, {
      doNotCatchRespond: true,
    }).then((response) => {
      if (response.status === 200 && response.data) {
        if (payload) {
          updateRestData(payload);
        }
      } else if (
        response.data?.category?.[0]?.includes("Cannot set category.")
      ) {
        dispatch(setLoading(false));
        rulesRef.current?.onOpen({
          // payload: [{ ...obj, uuid: id }],//do not add
          message: response?.data?.category?.[0],
          oldObj: obj,
          response: [{ ...response?.data, uuid: id }],
          transaction_rule: response.data?.transaction_rule?.[0],
          rule_type: response.data?.type?.[0],
          title: obj?.title,
          transaction_type: obj?.gross_value >= 0 ? 1 : 2,
          category: obj?.category,
          callback: (updatedObj, action) => {
            if (props?.setCardItem) {
              props?.setCardItem((draft) => ({ ...draft, ...updatedObj }));
              if (payload) {
                updateRestData({ ...payload, action });
              }
            }
          },
        });
      }
    });
  };

  const updateRestData = async ({
    oldRecurData = [],
    array = [],
    _contact,
    recurring_type,
    doNotUpdateApp,
    onCloseEditForm,
    cardItem,
    recurring_Obj,
    value_sets,
    isLoanSpecialPayments,
    SpecialPaymentsItemList,
    updatedObj,
    updatedRecurringRule,
    title,
    action = {},
  }) => {
    let updateData = [];
    let addData = [];
    let deleteData = [];
    await updateRecurringRules(
      cardItem?.recurring_rule,
      {
        ...recurring_Obj,
        ...updatedRecurringRule,
        contact: _contact || recurring_Obj?.contact,
        transaction_category: cardItem?.category,
      },
      recurring_type !== "employee",
      recurring_type,
      value_sets
    );
    let seqInfo = {};
    array?.forEach((element) => {
      const filterObj = oldRecurData?.[0];
      if (filterObj) {
        if (isLoanSpecialPayments) {
          seqInfo.state = filterObj?.state || "Planned";
          seqInfo.scenario = filterObj?.scenario || "Base";
          seqInfo.title = filterObj?.title;
          seqInfo.note = filterObj?.note;
        }
        updateData.push({
          ...element,
          ...seqInfo,
          skip_transaction_rule: recurring_type === "employee",
          ...action,
          uuid: filterObj?.uuid,
          contact: recurring_type === "employee" ? _contact : null,
        });
        oldRecurData?.splice(0, 1);
      } else {
        addData.push({
          ...element,
          ...seqInfo,
          skip_transaction_rule: recurring_type === "employee",
          ...action,
          data_source: cardItem?.data_source,
          contact: recurring_type === "employee" ? _contact : null,
        });
      }
    });
    if (oldRecurData?.length > 0) {
      deleteData = oldRecurData?.map((o1) => o1.uuid);
    }

    // console.log("array:", array);
    // console.log("addData:", [...addData]);
    // console.log("updateData:", updateData);
    // console.log("deleteData:", deleteData);

    if (
      recurring_Obj?.recurring_type === "loan" ||
      recurring_Obj?.recurring_type === "leasing"
    ) {
      if (updatedObj?.current?.loanIncomeTransaction?.uuid) {
        updateData.push({
          ...updatedObj?.current?.loanIncomeTransaction,
          data_source: cardItem?.data_source,
          title,
          note: cardItem?.note || "",
          due_date: recurring_Obj?.start_date,
          gross_value: recurring_Obj?.value,
          skip_transaction_rule: true,
        });
      } else {
        addData.push({
          recurring_rule: recurring_Obj?.uuid,
          data_source: cardItem?.data_source,
          title,
          note: cardItem?.note || "",
          source: cardItem?.source || "1",
          due_date: recurring_Obj?.start_date,
          gross_value: recurring_Obj?.value,
          skip_transaction_rule: true,
          state: updatedObj?.current?.loanIncomeTransaction?.state,
          scenario: updatedObj?.current?.loanIncomeTransaction?.scenario,
          category: updatedObj?.current?.loanIncomeTransaction?.category,
          bank_transaction_type:
            updatedObj?.current?.loanIncomeTransaction?.bank_transaction_type,
        });
      }
    }

    if (
      recurring_Obj?.recurring_type === "loan" &&
      SpecialPaymentsItemList?.length > 0
    ) {
      SpecialPaymentsItemList?.forEach((o1) => {
        updateData.push({
          ...o1,
          uuid: o1?.uuid,
          category: cardItem?.category,
          ...action,
        });
      });
    }
    if (addData?.length > 0) {
      await addBatchTransactions(addData);
    }
    if (updateData?.length > 0) {
      await updateBatchTransactions(updateData);
    }
    if (deleteData?.length > 0) {
      await deleteBatchTransactions(deleteData);
    }
    sleep(0);
    dispatch(setLoading(false));

    if (!doNotUpdateApp) {
      resetQuery();
    }
    if (onCloseEditForm) {
      onCloseEditForm();
    }
  };

  //functions
  const resetQuery = () => {
    global.allowFetch = { Inflow: [], Outflow: [] };
    let options = {
      predicate: (query) =>
        initialData.ResetAllDataOptions.includes(query.queryKey[0]),
    };
    queryClient.resetQueries(options, { cancelRefetch: true });
  };

  const createRecurArray = ({
    recurring_Obj,
    recurring_rule,
    gross_value,
    payUsersNumber,
    cardItem,
    transaction_type,
    _contact,
    isPastIncluded,
    PausedDuration,
    title,
  }) => {
    let {
      recurring_type,
      repetition,
      average_value,
      users_growth,
      churn,
      conversion,
      new_user_per_month,
      start_date,
      end_date,
      growth,
      tax,
      value_sets,
    } = recurring_Obj;

    const excludeFirstMonth = recurring_type === "subscription";
    let dateArray = getRecurDateArray(repetition, start_date, end_date);
    if (excludeFirstMonth) {
      dateArray.splice(0, 1);
    }
    let array = [];

    let oneTimeCost = 0;
    let onGoingCost = 0;

    dateArray?.forEach((item, index) => {
      if (recurring_type === "loan") {
        const formattedDate = format(item, "yyyy-MM");
        if (PausedDuration?.includes(formattedDate)) {
          return;
        }
      }
      if (recurring_type === "employee") {
        if (index === 0) {
          gross_value = Math.abs(Number(gross_value || 0));
          value_sets?.[0]?.items?.forEach((o1) => {
            let share = 0;
            if (Number(o1.type) === 2) {
              if (o1?.value && Number(o1?.value) !== 0) {
                share = (Number(gross_value) * Number(o1?.value)) / 100;
              }
            } else {
              share = o1?.value;
            }
            onGoingCost =
              Number(onGoingCost || 0) + Number(Math.abs(share || 0));
          });
          value_sets?.[1]?.items?.forEach((o1) => {
            let share = 0;

            if (Number(o1.type) === 2) {
              if (o1?.value && Number(o1?.value) !== 0) {
                share = (Number(gross_value) * Number(o1?.value)) / 100;
              }
            } else {
              share = o1?.value;
            }
            oneTimeCost = oneTimeCost + Number(Math.abs(share || 0));
          });

          gross_value = Number(gross_value || 0) + Number(onGoingCost || 0);
          gross_value = Number(gross_value || 0) + Number(oneTimeCost || 0);
        } else {
          gross_value =
            Math.abs(Number(gross_value || 0)) - Number(oneTimeCost || 0);
          oneTimeCost = 0;
        }
      }
      if (recurring_type === "subscription") {
        if (index === 0) {
          let obj = {
            recurring_rule,
            title,
            tax,
            gross_value: parseFloat(gross_value)?.toFixed(0),
            note: cardItem?.note || "",
            position: cardItem?.position || 1001,
            source: cardItem?.source || "1",
            cost_unit: cardItem?.cost_unit,
            data_source: cardItem?.data_source,
            state: cardItem?.state,
            contact: _contact || cardItem?.contact,
            scenario: cardItem?.scenario,
            category: cardItem?.category,
            due_date: format(new Date(start_date), "yyyy-MM-dd"),
            currency: cardItem?.currency,
            invoice_date: cardItem?.invoice_date,
          };

          array.push(obj);
        }
        let user = payUsersNumber;
        if (users_growth && Number(users_growth) !== 0) {
          new_user_per_month =
            Number(new_user_per_month) +
            Number(new_user_per_month * users_growth) / 100;
        }
        user = Number(payUsersNumber) + Number(new_user_per_month || 0);

        if (churn && Number(churn) !== 0) {
          user = user - (user * churn) / 100;
        }
        payUsersNumber = user;
        gross_value = user * average_value;
      }
      if (recurring_type === "shop") {
        let conv = 1;
        if (users_growth && Number(users_growth) !== 0) {
          payUsersNumber =
            payUsersNumber + (payUsersNumber * users_growth) / 100;
        }
        if (conversion && Number(conversion) !== 0) {
          conv = conversion / 100;
        }
        gross_value = payUsersNumber * average_value * conv;
      }
      if (
        recurring_type === "simple" ||
        recurring_type === "advanced" ||
        recurring_type === "client" ||
        recurring_type === "products" ||
        recurring_type === "time"
      ) {
        let grow = 0;
        if (growth && Number(growth) !== 0) {
          grow = (gross_value * growth) / 100;
        }
        gross_value = gross_value + grow;
      }
      if (transaction_type === "expense") {
        gross_value = String(gross_value)?.includes("-")
          ? gross_value
          : -gross_value;
      }

      let obj = {
        tax,
        title,
        recurring_rule,
        gross_value: parseFloat(gross_value)?.toFixed(0),
        note: cardItem?.note || "",
        position: cardItem?.position || 1001,
        source: cardItem?.source || "1",
        cost_unit: cardItem?.cost_unit,
        state: cardItem?.state,
        scenario: cardItem?.scenario,
        contact: _contact || cardItem?.contact,
        data_source: cardItem?.data_source,
        category: cardItem?.category,
        due_date: format(item, "yyyy-MM-dd"),
        currency: cardItem?.currency,
        invoice_date: cardItem?.invoice_date,
      };
      // calculated_vat_value is calculated as below:
      // if tax_value is NOT null, we take it
      // if tax_value is null:
      // if tax is NOT null, we calculate vat = gross * tax
      // if tax is null, we calculate vat = gross * category.tax
      if (recurring_type === "employee" && !tax) {
        obj.net_value = parseFloat(gross_value)?.toFixed(0);
        obj.tax_value = 0;
      } else {
        obj.net_value = null;
        obj.tax_value = null;
      }
      array.push(obj);
    });
    let filterArray = array;
    if (cardItem?.recurring_rule && !isPastIncluded) {
      filterArray = array.filter((item) => {
        return item.due_date >= format(startOfMonth(new Date()), "yyyy-MM-dd");
      });
    }
    return filterArray;
  };

  function checkVariableDateRangeAndGaps(array, rangeStart, rangeEnd) {
    // Convert rangeStart and rangeEnd to Date objects
    const start = new Date(rangeStart);
    const end = new Date(rangeEnd);

    // Check if the first object's start_date is equal to rangeStart
    const firstItemStartDate = new Date(array[0].start_date);
    if (!isSameMonth(start, firstItemStartDate)) {
      return false; // First start_date does not match the rangeStart
    }

    // Check if the last object's end_date is equal to rangeEnd
    const lastItemEndDate = new Date(array[array.length - 1].end_date);
    if (!isSameMonth(lastItemEndDate, end)) {
      return false; // Last end_date does not match the rangeEnd
    }

    // Check for any gaps between consecutive items
    for (let i = 0; i < array.length - 1; i++) {
      const currentEndDate = new Date(array[i].end_date);
      const nextStartDate = array[i + 1].start_date
        ? new Date(array[i + 1].start_date)
        : null;

      // There should be no gap between the current end_date and next start_date
      if (
        nextStartDate &&
        !isSameMonth(addMonths(currentEndDate, 1), nextStartDate)
      ) {
        return false; // Found a gap between consecutive ranges
      }
    }

    return true; // All checks passed, no gaps found, range is correct
  }

  const onSaveRecurrence = async (obj) => {
    let {
      cardItem,
      setCardItem,
      recurring_Obj,
      setRecurring_Obj,
      transaction_type,
      selected_scenarios,
      deleteIds,
      oldRecurringObj,
      updatedObj,
      doNotUpdateApp,
      onCloseEditForm,
      setShowError,
      isPastIncluded,
      PausedDuration,
      isLoanSpecialPayments,
      PausedDurationItemList,
      SpecialPaymentsItemList,
      VariableRatesItemList,
    } = obj;
    let {
      recurring_type,
      source,
      average,
      category,
      users,
      average_value,
      conversion,
      value,
      payment_default,
      discount,
      capacity,
      workers,
      working_hours,
      holiday,
      average_vacation_day,
      average_sick_day,
      period,
      repetition,
      end_date,
      start_date,
      multiplier,
      employee_type,
      value_sets,
      salary_type,
      contact,
      name,
      interest_rate,
      loan_calculation_method,
      deposit_value,
    } = recurring_Obj;
    let errorText = "";
    if (recurring_type === "loan") {
      if (!value || Number(value) === 0) {
        errorText = "please add an loan principal amount"; //
        if (setShowError) setShowError(errorText);
        return null;
      }
      if (loan_calculation_method === 2 && !payment_default) {
        errorText = "please add an loan term in  months"; //
        if (setShowError) setShowError(errorText);
        return null;
      }
      if (
        loan_calculation_method !== 2 &&
        (!average_value || Number(average_value) === 0)
      ) {
        errorText = "please add an monthly loan repayment amount"; //
        if (setShowError) setShowError(errorText);
        return null;
      }
      if (Number(average_value) > value) {
        errorText = "repayment amount can not be more than principal amount"; //
        if (setShowError) setShowError(errorText);
        return null;
      }
      if (!interest_rate || Number(interest_rate) === 0) {
        errorText = "please add an loan interest rate"; //
        if (setShowError) setShowError(errorText);
        return null;
      }
      if (interest_rate && Number(interest_rate) > 30) {
        errorText = "loan interest rate can not be more than 30%"; //
        if (setShowError) setShowError(errorText);
        return null;
      }
    }
    if (recurring_type === "leasing") {
      if (!value || Number(value) === 0) {
        errorText = "please add an leasing contract amount"; //
        if (setShowError) setShowError(errorText);
        return null;
      }
      if (Number(average_value) > value) {
        errorText = "repayment amount can not be more than principal amount"; //
        if (setShowError) setShowError(errorText);
        return null;
      }
      if (!payment_default) {
        errorText = "please add an leasing term in  months"; //
        if (setShowError) setShowError(errorText);
        return null;
      }

      if (!interest_rate || Number(interest_rate) === 0) {
        errorText = "please add an leasing interest rate"; //
        if (setShowError) setShowError(errorText);
        return null;
      }
      if (interest_rate && Number(interest_rate) > 30) {
        errorText = "leasing interest rate can not be more than 30%"; //
        if (setShowError) setShowError(errorText);
        return null;
      }
    }
    if (!start_date) {
      errorText = "please add a start date";
      if (setShowError) setShowError(errorText);
      return null;
    }
    if (
      recurring_type === "leasing" || recurring_type === "loan"
        ? false
        : !end_date
    ) {
      errorText = "please add an end date";
      if (setShowError) setShowError(errorText);
      return null;
    }
    if (
      recurring_type === "loan" || recurring_type === "leasing"
        ? false
        : !period
    ) {
      errorText = "please add a period";
      if (setShowError) setShowError(errorText);
      return null;
    }
    if (!repetition) {
      if (recurring_type === "loan" || recurring_type === "leasing") {
        errorText = "please add a installments"; //
      } else {
        errorText = "please add a repetition";
      }
      if (setShowError) setShowError(errorText);
      return null;
    }
    if (recurring_type === "employee" && !contact) {
      errorText = "please add an employee name";
      if (setShowError) setShowError(errorText);
      return null;
    }

    if (
      recurring_type === "employee" &&
      (salary_type ? !value || Number(value) === 0 : false)
    ) {
      errorText = "please add an employee salary";
      if (setShowError) setShowError(errorText);
      return null;
    }
    if (recurring_type === "simple" && (!value || Number(value) === 0)) {
      errorText = "please add an amount";
      if (setShowError) setShowError(errorText);
      return null;
    }
    if (
      recurring_type !== "simple" &&
      recurring_type !== "employee" &&
      recurring_type !== "advanced" &&
      recurring_type !== "loan" &&
      recurring_type !== "leasing" &&
      !average_value
    ) {
      errorText = "please add an amount";
      if (setShowError) setShowError(errorText);
      return null;
    }

    if (recurring_type === "advanced" && (!source || !average)) {
      if (!source) errorText = "please add advance rule type";
      if (!average) errorText = "please add advance rule average";
      if (setShowError) setShowError(errorText);
      return null;
    }
    if (recurring_type === "employee" && (!employee_type || !category)) {
      if (!employee_type) errorText = "please add an employee type";
      if (!category) errorText = "please add an employee category";
      if (setShowError) setShowError(errorText);
      return null;
    }
    if (
      recurring_type === "subscription" &&
      !users //multiplier=new user/ month
    ) {
      errorText = "please add an subscription users";
      if (setShowError) setShowError(errorText);
      return null;
    }
    if (recurring_type === "shop" && !users) {
      errorText = "please add an shop users";
      if (setShowError) setShowError(errorText);
      return null;
    }
    if (
      (recurring_type === "client" || recurring_type === "products") &&
      !capacity
    ) {
      if (recurring_type === "client")
        errorText = "please add an client capacity";
      if (recurring_type === "products")
        errorText = "please add an products capacity";
      if (setShowError) setShowError(errorText);
      return null;
    }
    if (recurring_type === "time" && (!workers || !working_hours)) {
      if (!workers) errorText = "please add an workers";
      if (!working_hours) errorText = "please add an working hours";
      if (setShowError) setShowError(errorText);
      return null;
    }
    if (
      start_date &&
      end_date &&
      (!isValid(new Date(start_date)) || !isValid(new Date(end_date)))
    ) {
      enqueueSnackbar(t("please enter valid date"), {
        variant: "error",
        autoHideDuration: 4000,
        preventDuplicates: true,
      });
      return null;
    }
    if (new Date(start_date)?.getTime() > new Date(end_date)?.getTime()) {
      enqueueSnackbar(t("End date should be greater than start date"), {
        variant: "error",
        autoHideDuration: 4000,
        preventDuplicates: true,
      });
      return;
    }
    let updatedRecurringRule = {};
    if (recurring_type === "leasing" || recurring_type === "loan") {
      if (loan_calculation_method === 2 || recurring_type === "leasing") {
        const { emi } = calculateEMI({
          principal: Math.abs(value || 0),
          downPayment: Math.abs(deposit_value || 0),
          annualInterestRate: Math.abs(interest_rate || 0),
          loanTenureInMonths: payment_default,
          VariableRatesItemList,
          start_date,
        });

        average_value = String(emi);
        start_date = start_date ? start_date : cardItem?.due_date;
        end_date = calculateEndDate({
          months: payment_default,
          start_date,
          extraMonth:
            recurring_type === "leasing" ? 0 : PausedDuration?.length || 0,
        });
        updatedRecurringRule["average_value"] = average_value;
        updatedRecurringRule["end_date"] = end_date;
      } else {
        start_date = start_date ? start_date : cardItem?.due_date;
        const LoanTenure = calculateLoanTenure({
          principal: Math.abs(value || 0),
          downPayment: Math.abs(deposit_value || 0),
          emi: Math.abs(average_value || 0),
          annualInterestRate: Math.abs(interest_rate || 0),
          VariableRatesItemList,
          start_date,
        });
        payment_default = String(LoanTenure);
        end_date = calculateEndDate({
          months: LoanTenure,
          start_date,
          extraMonth:
            recurring_type === "leasing" ? 0 : PausedDuration?.length || 0,
        });
        updatedRecurringRule["payment_default"] = payment_default;
        updatedRecurringRule["end_date"] = end_date;
      }

      if (setRecurring_Obj) {
        setRecurring_Obj({ ...recurring_Obj, ...updatedRecurringRule });
      }
    }
    if (recurring_type === "loan" && VariableRatesItemList?.length > 0) {
      const isValid = checkVariableDateRangeAndGaps(
        VariableRatesItemList,
        start_date,
        end_date
      );

      if (setShowError && !isValid) {
        setShowError({
          errorText: "add missing date range in variable rate list",
          variable_rate: true,
        });
        return null;
      }
    }

    let _contact = null;
    dispatch(setLoading(true));
    if (recurring_type === "employee") {
      let AllStaffList = await queryClient.fetchQuery({
        queryKey: getStaffQueryKey(),
        queryFn: ({ signal }) => {
          const result = getContacts({
            type: 3,
          });
          if (result) {
            return result;
          }
        },
        backgroundFetch: true,
      });
      let StaffList = AllStaffList?.filter(
        (o1) => o1?.dataset === dataSetData?.uuid
      );
      if (!contact) {
        _contact = StaffList?.find((o1) => o1?.name === name)?.uuid;

        if (!_contact) {
          _contact = await addContactApi({
            name: name,
            type: 3,
            dataset: dataSetData?.uuid,
          });
          AllStaffList = await queryClient.fetchQuery({
            queryKey: getStaffQueryKey(),
            queryFn: ({ signal }) => {
              const result = getContacts({
                type: 3,
              });
              if (result) {
                return result;
              }
            },
            backgroundFetch: true,
          });
          StaffList = AllStaffList?.filter(
            (o1) => o1?.dataset === dataSetData?.uuid
          );
        }
      } else {
        _contact = await updateContactApi(contact, {
          name: name,
        });
        AllStaffList = await queryClient.fetchQuery({
          queryKey: getStaffQueryKey(),
          queryFn: ({ signal }) => {
            const result = getContacts({
              type: 3,
            });
            if (result) {
              return result;
            }
          },
          backgroundFetch: true,
        });
        StaffList = AllStaffList?.filter(
          (o1) => o1?.dataset === dataSetData?.uuid
        );
      }
      if (!_contact) {
        dispatch(setLoading(false));
        return;
      }
    }

    let gross_value =
      recurring_type === "simple" || recurring_type === "employee"
        ? Number(value)
        : Number(average_value);
    let payUsersNumber = users ? Number(users) : 0;
    if (recurring_type === "simple") {
      let disc = 0;
      if (discount && Number(discount) !== 0) {
        disc = (gross_value * discount) / 100;
      }
      gross_value = gross_value - disc;
    }
    if (recurring_type === "advanced") {
      let Divide = average === 1 ? average : average + 1;
      let startDate = new Date(startOfMonth(subMonths(new Date(), Divide)));
      let endDate = new Date(endOfMonth(subMonths(new Date(), 1)));
      const response = await getStatisticsDataWithParams({
        type: "monthly",
        dataset: dataSetData?.uuid,
        from_payment_date: format(startDate, "yyyy-MM-dd"),
        to_payment_date: format(endDate, "yyyy-MM-dd"),
        multiStatesIds: [stateByTitle?.["Booked"]?.[0]?.uuid],
      });

      const groupedMonthlyTransactions = _.groupBy(
        response?.results ?? [],
        ({ month }) => month
      );

      let result = eachMonthOfInterval({
        start: startDate,
        end: endDate,
      });

      let total = 0;
      if (source === 2) {
        result?.forEach((element) => {
          let date = format(element, "yyyy-MM");
          let monthData = groupedMonthlyTransactions[date];
          total =
            total +
            Math.abs(
              monthData
                ? monthData
                    ?.filter(
                      (o1) =>
                        (category ? o1.category === category : !o1.category) &&
                        (selected_scenarios?.length > 0
                          ? selected_scenarios.includes(o1.scenario_uuid)
                          : true)
                    )
                    ?.reduce(
                      (total, item) =>
                        parseFloat(total) +
                        parseFloat(
                          transaction_type === "income"
                            ? item?.inflow ?? 0
                            : item?.outflow ?? 0
                        ),
                      0
                    )
                : 0
            );
        });
      }
      if (source === 3) {
        result?.forEach((element) => {
          let date = format(element, "yyyy-MM");
          let monthData = groupedMonthlyTransactions[date];
          total =
            total +
            Math.abs(
              monthData
                ? monthData?.reduce(
                    (total, item) =>
                      parseFloat(total) + parseFloat(item?.inflow ?? 0),
                    0
                  )
                : 0
            );
        });
      }
      if (source === 4) {
        result?.forEach((element) => {
          let date = format(element, "yyyy-MM");
          let monthData = groupedMonthlyTransactions[date];
          total =
            total +
            Math.abs(
              monthData?.reduce(
                (total, item) =>
                  parseFloat(total) + parseFloat(item?.outflow ?? 0),
                0
              )
            );
        });
      }
      //////
      if (total === 0) {
        enqueueSnackbar(t("adv_seq_category_no_transactions"), {
          variant: "warning",
          autoHideDuration: 5000,
          preventDuplicate: true,
        });
        dispatch(setLoading(false));
        return null;
      }
      //////
      let multiply = 1;
      let finalValue = total / Divide;
      if (multiplier && Number(multiplier) !== 0) {
        multiply = multiplier / 100;
      }
      gross_value = Number(finalValue) * Number(multiply);
    }
    if (recurring_type === "subscription") {
      let user = payUsersNumber;
      let disc = 0;
      if (discount && Number(discount) !== 0) {
        disc = (average_value * discount) / 100;
      }
      gross_value = user * (average_value - disc);
    }
    if (recurring_type === "shop") {
      let conv = 1;
      let disc = 0;
      if (discount && Number(discount) !== 0) {
        disc = (average_value * discount) / 100;
      }
      if (conversion && Number(conversion) !== 0) {
        conv = conversion / 100;
      }
      gross_value = users * (average_value - disc) * conv;
    }
    if (recurring_type === "client" || recurring_type === "products") {
      let disc = 0;
      let dif = capacity;

      if (discount && Number(discount) !== 0) {
        disc = (average_value * discount) / 100;
      }
      if (payment_default && Number(payment_default) !== 0) {
        dif = capacity - (capacity * payment_default) / 100;
      }
      gross_value = (average_value - disc) * dif;
    }
    if (recurring_type === "time") {
      let disc = 0;
      let day = 365;
      if (discount && Number(discount) !== 0) {
        disc = (average_value * discount) / 100;
      }
      if (holiday && Number(holiday) !== 0) {
        day = day - holiday;
      }
      if (average_vacation_day && Number(average_vacation_day) !== 0) {
        day = day - average_vacation_day;
      }
      if (average_sick_day && Number(average_sick_day) !== 0) {
        day = day - average_sick_day;
      }
      let discountedValue = average_value - disc;
      let calculatedValue = day * working_hours;
      gross_value = (calculatedValue * discountedValue * workers) / 12;
      if (payment_default && Number(payment_default) !== 0) {
        gross_value = gross_value - (gross_value * payment_default) / 100;
      }
    }
    if (recurring_type === "employee" && Number(salary_type) === 2) {
      gross_value = Math.abs(gross_value) / 12;
    }

    if (transaction_type === "expense") {
      gross_value = String(gross_value)?.includes("-")
        ? gross_value
        : -gross_value;
    }

    let recurring_rule = cardItem?.recurring_rule
      ? cardItem?.recurring_rule
      : v4uuid();
    let isAlreadyHaveRecurring_rule = cardItem?.recurring_rule ? true : false;

    const _isPastIncluded =
      !isAlreadyHaveRecurring_rule ||
      updatedObj?.current?.hasOwnProperty("category") ||
      updatedObj?.current?.start_date ||
      updatedObj?.current?.end_date
        ? true
        : isPastIncluded;
    let title = cardItem?.title;
    if (!cardItem?.title) {
      if (recurring_type === "loan" || recurring_type === "leasing") {
        const recurring_rules = store.getState().globalSlice?.recurring_rules;
        const loanData = recurring_rules?.filter(
          (o1) => o1.recurring_type === recurring_type
        );
        let count = loanData?.length || 0;
        if (count?.toString()?.length === 1) {
          count = `00${count}`;
        }
        if (count?.toString()?.length === 2) {
          count = `0${count}`;
        }
        title = `${t(recurring_type)} - ${count}`;
      } else {
        const categoryLabel =
          selectionCategoriesByID?.[cardItem?.category]?.[0]?.label;
        title = `Planning Value - ${formatDateToLocal(subMonths(new Date(), 1), "MMMM yy")}${
          categoryLabel ? ` - ${categoryLabel}` : ""
        }`;
      }
    }
    if (recurring_type === "employee") {
      title = cardItem?.title;
    }
    let array = createRecurArray({
      title,
      recurring_Obj,
      recurring_rule,
      gross_value,
      payUsersNumber,
      cardItem,
      transaction_type,
      _contact,
      isPastIncluded: _isPastIncluded,
      PausedDuration,
    });

    internalDsRef.current?.enableInternalDS();
    if (deleteIds?.length > 0) {
      await deleteBatchTransactions(deleteIds);
    }

    if (!cardItem?.recurring_rule) {
      let obj = {
        ...recurring_Obj,
        ...updatedRecurringRule, //for loan and leasing
        name: name || recurring_Obj?.recurring_type,
        contact: _contact, //for staff
        transaction_category: cardItem?.category,
        period: -2,
        uuid: recurring_rule,
        value_sets: [],
      };
      if (oldRecurringObj) {
        await updateRecurringRules(
          oldRecurringObj?.uuid,
          oldRecurringObj,
          false
        );
      }
      await addRecurringRules(obj, oldRecurringObj, recurring_type, value_sets);
      if (recurring_type === "loan" && PausedDurationItemList?.length > 0) {
        await addPausedDurations(recurring_rule, PausedDurationItemList);
      }
      if (recurring_type === "loan" && VariableRatesItemList?.length > 0) {
        await addVariableList(recurring_rule, VariableRatesItemList);
      }
      if (recurring_type === "loan" && SpecialPaymentsItemList?.length > 0) {
        SpecialPaymentsItemList?.forEach((o1) => {
          array.push({
            ...o1,
            recurring_rule,
            category: cardItem?.category,
            currency: cardItem?.currency,
            data_source: cardItem?.data_source,
            state: "Booked",
            scenario: "Base",
            bank_transaction_type: "LOAN_SPECIAL_PAYMENT",
          });
        });
      }
      if (
        recurring_Obj?.recurring_type === "loan" ||
        recurring_Obj?.recurring_type === "leasing"
      ) {
        array.push({
          ...updatedObj?.current?.loanIncomeTransaction,
          data_source: cardItem?.data_source,
          title,
          recurring_rule,
          note: cardItem?.note || "",
          source: cardItem?.source || "1",
          due_date: recurring_Obj?.start_date,
          gross_value: recurring_Obj?.value,
          skip_transaction_rule: true,
        });
      }
    }
    if (setCardItem) {
      setCardItem((prev) => ({
        ...prev,
        recurring_rule: recurring_rule,
        gross_value: gross_value?.toFixed(0),
      }));
    }
    if (isAlreadyHaveRecurring_rule) {
      let oldDataParams = {
        dataset: dataSetData?.uuid,
        recurring_rule: [recurring_rule],
        is_reconciled: false,
        startDate: _isPastIncluded ? null : startOfMonth(new Date()),
        state:
          recurring_type === "loan"
            ? Constant?.staffState?.map((o1) => stateByTitle?.[o1]?.[0]?.uuid)
            : [],
      };
      if (isPastIncluded) {
        oldDataParams.startDate = null;
      }
      const oldRecurData = await getAllTransactionsByParams(oldDataParams);

      if (updatedObj) {
        updatedObj.current = {
          ...updatedObj.current,
          recurring_rule: recurring_rule,
        };
      }
      const payload = {
        oldRecurData,
        array,
        _contact,
        recurring_type,
        doNotUpdateApp,
        onCloseEditForm,
        cardItem,
        recurring_Obj,
        value_sets,
        isLoanSpecialPayments,
        SpecialPaymentsItemList,
        updatedObj,
        updatedRecurringRule,
        title,
      };
      if (recurring_type === "employee" || !updatedObj?.current?.category) {
        updateRestData(payload);
      } else {
        await updateCardByID(
          cardItem?.uuid,
          { category: cardItem?.category },
          payload
        );
      }
    } else {
      setTimeout(() => {
        addBatch(array, onCloseEditForm);
      }, 1000);
    }
  };

  return (
    <>
      <RulesCommonView ref={rulesRef} />
      <EnableInternalDSFunctions ref={internalDsRef} />
    </>
  );
});

export default RecurringSeqFunctions;
