import {
  DialogActions,
  DialogContent,
  Typography,
  CardMedia,
  Divider,
  Tooltip,
  Dialog,
  Stack,
  Fade,
  Link,
  Box,
} from "@mui/material";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import TipsAndUpdatesTwoToneIcon from "@mui/icons-material/TipsAndUpdatesTwoTone";
import ImportExportOutlinedIcon from "@mui/icons-material/ImportExportOutlined";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";
import { TbChartAreaLineFilled, TbCircles } from "react-icons/tb";
import RuleOutlinedIcon from "@mui/icons-material/RuleOutlined";
import { useContext, useEffect, useRef, useState } from "react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import { format, startOfMonth, subMonths } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { BsCurrencyExchange } from "react-icons/bs";
import HttpIcon from "@mui/icons-material/Http";
import { useTranslation } from "react-i18next";
import ApiIcon from "@mui/icons-material/Api";
import { useSnackbar } from "notistack";

import {
  setIsDsUuidUpdating,
  setIsDsUuidLoading,
} from "../../../store/slices/global";
import GoogleSheetHeaderMappingDialogView from "./GoogleSheetHeaderMappingDialogView";
import TailwindButton from "../../../components/Overlay/TailwindButton";
import { setStageLoadingText } from "../../../store/slices/appmain";
import { setPopupStatus3 } from "../../../store/slices/datasets";
import TitleInput from "../../../components/Overlay/TitleInput";
import DateInput from "../../../components/Overlay/DateInput";
import { GlobalContext } from "../../../GlobalContextWrapper";
import DropDown from "../../../components/Overlay/DropDown";
import { setAddFrom } from "../../../store/slices/settings";
import Translate from "../../../hooks/HOC/Translate";
import EndPoints from "../../../APICall/EndPoints";
import { Color, Constant } from "../../../Helper";
import SheetComponent from "./SheetComponent";
import Icon from "../../../components/Icon";
import DSConnections from "./DSConnections";
import { queryClient } from "../../../App";
import APICall from "../../../APICall";
import theme from "../../../theme";

const SCOPES = "https://www.googleapis.com/auth/spreadsheets.readonly";

const TransactionSystemDialogView = ({
  open,
  handleClickClose,
  cell,
  clickable = false,
}) => {
  const dsRef = useRef(null);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const globalContext = useContext(GlobalContext);

  const finbanInfoRef = globalContext?.finbanInfoRef;
  const dSObj = useRef(cell);
  const isDataUpdated = useRef(false);
  let abortController = new AbortController();
  let aborted = abortController.signal.aborted;

  //redux
  const dataSource = useSelector((state) => state.globalSlice.dataSource);
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);

  //state
  const [item, setItem] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisable] = useState(false);
  const [isGSheetHeaderMappingOpen, setIsGSheetHeaderMappingOpen] =
    useState(false);
  const [isSheetImportFlow, setIsSheetImportFlow] = useState(false);

  let showApiKey = false;
  let showApiClient = false;
  let showApiSecret = false;
  let showFromDate = false;
  let showIntegrationState = false;
  let showApiUrl = false;
  let ImageWidth = "6rem";
  let showSheetId = false;
  let showSettings = false;
  if (
    ["Google Sheets API", "Excel import"].includes(dSObj.current?.card?.name)
  ) {
    showSettings = true;
  }
  if (["Google Sheets API"].includes(dSObj.current?.card?.name)) {
    showSheetId = true;
  }
  if (
    [
      "easybill",
      "BuchhaltungsButler",
      "GetMyInvoices",
      "Lexoffice",
      "Moco",
      "SevDesk",
    ].includes(dSObj.current?.card?.name)
  ) {
    showIntegrationState = true;
  }
  if (
    [
      "easybill",
      "BuchhaltungsButler",
      "GetMyInvoices",
      "Google Sheets API",
      "Excel import",
    ].includes(dSObj.current?.card?.name)
  ) {
    ImageWidth = "3rem";
  }

  if (
    [
      "Moco",
      "SevDesk",
      "BuchhaltungsButler",
      "easybill",
      "GetMyInvoices",
    ].includes(dSObj.current?.card?.name)
  ) {
    showApiKey = true;
  }
  if (
    [
      "Lexoffice",
      "Moco",
      "SevDesk",
      "BuchhaltungsButler",
      "easybill",
      "GetMyInvoices",
    ].includes(dSObj.current?.card?.name)
  ) {
    showFromDate = true;
  }
  if (["Moco"].includes(dSObj.current?.card?.name)) {
    showApiUrl = true;
  }
  if (["BuchhaltungsButler"].includes(dSObj.current?.card?.name)) {
    showApiClient = true;
    showApiSecret = true;
  }

  const DataOverridingOptions = [
    {
      label: t("Override all"),
      value: 1,
    },
    {
      label: t(
        "Do not override due date, state, scenario, category, note if they have been changed"
      ),
      value: 2,
    },
  ];

  const ErrorHandlingOptions = [
    {
      label: t("Go on with next line and collect errors"),
      value: 1,
    },
    {
      label: t("Cancel complete sync"),
      value: 2,
    },
  ];

  const DataImportingOptions = [
    {
      label: t("Erase existing transactions and import"),
      value: 1,
    },
    {
      label: t("Keep existing transactions and import"),
      value: 2,
    },
  ];

  const currencies = [
    {
      uuid: 1,
      code: "USD",
      name: t("currency_dollar"),
      symbol: "$",
    },
    {
      uuid: 2,
      code: "EUR",
      name: t("currency_euro"),
      symbol: "€",
    },
    {
      uuid: 3,
      code: "GBP",
      name: t("currency_pound"),
      symbol: "£",
    },
  ];

  const isGsheetHeaderMappingNotAdded =
    showSheetId &&
    dSObj.current?.modalType === "Edit" &&
    (!dSObj.current?.api_key?.authorization_code ||
      ((dSObj.current?.api_key?.token ||
        dSObj.current?.api_key?.refresh_token) &&
        Object.keys(dSObj.current?.api_key?.headers).length === 0));

  const isExcelImportNotCompleted =
    dSObj.current?.card?.name === "Excel import" &&
    dSObj.current?.modalType === "Edit" &&
    (dSObj.current?.uploaded_file || dSObj.current?.sheet_name) &&
    Object.keys(dSObj.current?.headers).length === 0;

  // console.log("isExcelImportNotCompleted :>> ", isExcelImportNotCompleted);
  if (isGsheetHeaderMappingNotAdded) {
    isDataUpdated.current = true;
  }
  let tooltip = "";
  if (dSObj.current?.ds?.internal_dataset) {
    tooltip = t("Internal data source tooltip");
  } else if (dSObj.current?.acc?.bank_details?.title) {
    tooltip = `${dSObj.current?.acc?.bank_details?.title}${
      dSObj.current?.acc?.iban ? ` / ${dSObj.current?.acc?.iban}` : ""
    }`;
  } else {
    tooltip = dSObj.current?.card?.name;
  }

  useEffect(() => {
    if (showSheetId) {
      const gisLoadedScript = document.createElement("script");
      gisLoadedScript.src = "https://accounts.google.com/gsi/client";
      document.body.appendChild(gisLoadedScript);
    }
  }, [showSheetId]);

  //api
  const addDataSourceDataset = async (dataset_id, obj, dsId) => {
    await APICall(
      "post",
      EndPoints.datasets + `${dataset_id}/data_sources/`,
      obj
    ).then((response) => {
      if (response.status === 201 && response.data && aborted === false) {
      }
      if (response.status === 400 && response.data) {
        if (response?.data?.data_source?.length > 0) {
          enqueueSnackbar(t("different_ds_not_allowed"), {
            variant: "warning",
            autoHideDuration: 5000,
          });
        }
      }
    });
  };

  const updateDSByID = async (id, obj) => {
    await APICall("patch", EndPoints.integrations + `${id}/`, obj).then(
      (response) => {
        if (response.status === 200 && response.data) {
        }
      }
    );
  };

  const createNewDS = async () => {
    await dsRef.current?.checkDemoDsConnections();
    let obj = {
      title: dSObj.current?.ds?.title,
      note: dSObj.current?.ds?.note || dSObj.current?.card?.name,
      alias: dSObj.current?.ds?.alias || "",
      transaction_system: dSObj.current?.transaction_systems?.uuid,
      type: dSObj.current?.ds?.type,
      state: 1,
      datasets: [],
      currency: dSObj.current?.ds?.currency || "EUR",
    };
    await APICall("post", EndPoints.integrations, obj).then(
      async (response) => {
        if (response.status === 201 && response.data) {
          dSObj.current = {
            ...dSObj.current,
            ds: response.data,
          };
          setDisable(true);
          afterDsCreate(response.data?.uuid, clickable);
        }
      }
    );
  };

  const addApiKey = async (integration_id, obj) => {
    await APICall(
      "put",
      EndPoints.integrations + `${integration_id}/external/config/`,
      obj,
      {
        ...(dSObj.current?.card?.name === "Excel import"
          ? { isFormData: true }
          : {}),
      }
    ).then(async (response) => {
      if (response.status === 200 && response.data) {
        if (dSObj.current?.card?.name === "Google Sheets API") {
          if (checkGoogleSheetIntegration(integration_id, response)) {
            return;
          }
        }
        let obj = {
          active: true,
          dataset: dataSetData?.uuid,
          data_source: integration_id,
        };
        if (clickable && dataSetData?.uuid) {
          await addDataSourceDataset(dataSetData?.uuid, obj);
        }
        if (dSObj.current?.card?.name === "Lexoffice") {
          dispatch(
            setPopupStatus3({
              id: "simple-popper",
              overlay_type: "warn_overlay",
              open: true,
              onConfirm: () => {
                getApiKey(dSObj.current?.ds?.uuid);
              },
              onClose: () => {
                setLoading(false);
              },
              payload: {
                title: <Translate i18nkey={"info_lexoffice_title"} />,
                message: <Translate i18nkey={"info_lexoffice_message"} />,
                confirmText: (
                  <Translate i18nkey={"info_lexoffice_confirm_text"} />
                ),
              },
            })
          );
          return;
        }
        setTimeout(() => {
          handleClose(true, clickable);
          setLoading(false);
        }, 0);
        dispatch(setIsDsUuidLoading(integration_id));
      } else {
        setLoading(false);
      }
      if (response.status === 400 && response.data) {
        if (response.data?.api_key) {
          setError({
            ...error,
            api_key: response.data?.api_key?.[0],
          });
        }
      }
    });
  };

  const updateApiKey = async (integration_id, obj) => {
    await APICall(
      "patch",
      EndPoints.integrations + `${integration_id}/external/config/`,
      obj,
      {
        ...(dSObj.current?.card?.name === "Excel import"
          ? { isFormData: true }
          : {}),
      }
    ).then(async (response) => {
      if (response.status === 200 && response.data) {
        if (dSObj.current?.card?.name === "Google Sheets API") {
          if (checkGoogleSheetIntegration(integration_id, response)) {
            return;
          }
        }
        if (dSObj.current?.card?.name === "Excel import") {
          dispatch(setIsDsUuidLoading(integration_id));
        } else if (dSObj.current?.card?.name !== "Lexoffice") {
          dispatch(setIsDsUuidUpdating(integration_id));
        }
        let options = {
          predicate: (query) =>
            query.queryKey[0] === "integrations" &&
            query.queryKey[1]?.data_source === integration_id &&
            query.queryKey[1]?.apiType === "data_source_external_connections",
        };
        queryClient.resetQueries(options);
        setTimeout(() => {
          handleClose(true, clickable);
          setLoading(false);
        }, 0);
      } else {
        setLoading(false);
      }

      if (response.status === 400 && response.data) {
        if (response.data?.api_key) {
          setError({
            ...error,
            api_key: response.data?.api_key?.[0],
          });
        }
      }
    });
  };

  const getApiKey = async (integration_id, skip = false) => {
    await APICall(
      "get",
      EndPoints.integrations + `${integration_id}/external/config/`
    ).then(async (response) => {
      if (response.status === 200 && response.data) {
        dSObj.current = { ...dSObj.current, ...response.data };
        if (
          dSObj.current?.card?.name === "Lexoffice" &&
          !skip &&
          response.data?.authorization_url
        ) {
          // dispatch(setIsAllHeaderApiFetched(false));
          dispatch(setPopupStatus3(null));
          dispatch(
            setAddFrom({
              integration_id: integration_id,
              targetUrl: window.location.pathname,
            })
          );
          setTimeout(() => {
            let win = window.open(response.data?.authorization_url, "_self");
            win.focus();
          }, 100);
        } else if (dSObj.current?.card?.name === "Google Sheets API") {
          //Set the data if flow is completed or user clicks edit
          if (response.data?.sheet_id) {
            const sheet_url = `${"https://docs.google.com/spreadsheets/d/"}${response.data?.sheet_id}/edit`;
            dSObj.current = {
              ...dSObj.current,
              sheet_url,
              api_key: response.data,
            };
          }
          setItem({
            ...response.data,
            currency: dSObj.current?.ds?.currency,
            title: dSObj.current?.ds?.title,
            sheet_url: dSObj.current?.sheet_url,
          });
          // if (checkGoogleSheetIntegration(integration_id, response)) {
          //   return;
          // }
        } else {
          setItem({
            ...response.data,
            currency: dSObj.current?.ds?.currency,
            title: dSObj.current?.ds?.title,
          });
        }
      }
    });
  };

  const getSheetHeaders = async (integration_id, headersSetByUser = null) => {
    dispatch(setStageLoadingText("fetching google sheet header"));
    setLoading(true);
    await APICall(
      "get",
      EndPoints.integrations + `${integration_id}/external/get-headers/`,
      null,
      { doNotCatchRespond: true }
    ).then(async (response) => {
      setLoading(false);
      dispatch(setStageLoadingText(null));
      if (response.status === 200 && response.data) {
        let headerMappingDialogPayload = {
          headersFromSheet: response.data,
        };
        if (headersSetByUser) {
          headerMappingDialogPayload.headersSetByUser = headersSetByUser;
        }
        setIsGSheetHeaderMappingOpen(headerMappingDialogPayload);
      }
      if (response.status === 400 && response.data) {
        enqueueSnackbar(response.data?.error, {
          variant: "error",
          autoHideDuration: 5000,
          preventDuplicate: true,
        });
      }
    });
  };

  //Google Sheets functions
  const checkGoogleSheetIntegration = (integration_id, response) => {
    //Proceed with google auth if there are no tokens
    if (
      dSObj.current?.sheet_url &&
      response.data?.client_id &&
      !response.data?.authorization_code
    ) {
      handleGoogleAuth(dSObj.current?.ds?.uuid, response.data?.client_id);
      return true;
    }
    //Fetch the headers if the headers are null but tokens are generated
    if (
      (response.data?.token || response.data?.refresh_token) &&
      Object.keys(response.data?.headers).length === 0
    ) {
      getSheetHeaders(integration_id);
      return true;
    }

    return false;
  };

  const handleGoogleAuth = (uuid, client_id) => {
    const googleTokenClient = window.google?.accounts?.oauth2?.initCodeClient({
      client_id: client_id,
      scope: SCOPES,
      ux_mode: "popup",
      callback: handleGoogleAuthResponse,
      error_callback: handleGoogleErrorResponse,
      state: uuid,
    });
    //Initialize the request code
    googleTokenClient.requestCode();
  };

  const handleGoogleAuthResponse = async (response) => {
    if (response.error) {
      setLoading(false);
    } else {
      if (response.state === dSObj.current?.ds?.uuid && response.code) {
        // extract the sheet id from url
        const MatchedUrl = dSObj.current?.sheet_url?.match(/\/d\/(.+)\//);
        const sheet_id = MatchedUrl?.[1];
        if (sheet_id) {
          const obj = {
            sheet_id,
            authorization_code: response.code,
          };
          await addApiKey(dSObj.current?.ds?.uuid, obj);
        } else {
          enqueueSnackbar(t("Invalid sheet url"), {
            variant: "error",
            autoHideDuration: 5000,
            preventDuplicate: true,
          });
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    }
  };

  const handleGoogleErrorResponse = () => {
    setLoading(false);
  };

  const handleOnCloseGSheetHeaderMapping = () => {
    setIsGSheetHeaderMappingOpen(false);
  };

  const updateGSheetsHeaders = (headers) => {
    setLoading(true);
    handleOnCloseGSheetHeaderMapping();
    addApiKey(dSObj.current?.ds?.uuid, { headers: headers });
  };

  const handleFinalSubmitOfExcelFlow = (data) => {
    setIsSheetImportFlow(false);
    dSObj.current = { ...dSObj.current, ...data };
    if (dSObj.current?.modalType === "Edit") {
      afterDsUpdated(dSObj.current?.ds?.uuid);
    } else {
      createNewDS();
    }
  };

  //function
  const handleClose = (doUpdate = false, _clickable = false) => {
    setError(null);
    dSObj.current = null;
    handleClickClose(doUpdate, _clickable);
  };

  const onClose = () => {
    handleClose(
      dSObj.current?.ds?.uuid && isDataUpdated.current ? true : false
    );
  };

  const onClickOutside = () => {
    handleClose();
  };

  const handleClickAdd = () => {
    let integration_id = dSObj.current?.ds?.uuid;
    if (showApiKey && !dSObj.current?.api_key) {
      setError({ ...error, api_key: "Please enter valid api key" });
      return false;
    }
    // if (showApiKey && dSObj.current?.api_key?.length > 255) {
    //   setError({
    //     ...error,
    //     api_key: "api key cannot be more than 255 characters",
    //   });
    //   return false;
    // }
    if (showApiUrl && !dSObj.current?.app_url) {
      setError({ ...error, app_url: "Please enter valid app url" });
      return false;
    }
    if (!dSObj.current?.ds?.currency) {
      setError({ ...error, currency: "please select currency" });
      return false;
    }
    if (
      showFromDate &&
      (!dSObj.current?.from_date || dSObj.current?.from_date === "Invalid Date")
    ) {
      setError({ ...error, from_date: "please select valid date" });
      return false;
    }
    if (showSheetId) {
      const sheetUrl = dSObj.current?.sheet_url;
      if (!sheetUrl) {
        setError({ ...error, sheet_url: "Please enter valid sheet link" });
        return false;
      }
      const regex =
        /^(https?:\/\/)?(www\.)?(docs\.google\.com\/spreadsheets\/d\/\w+\/?)(.*)$/;
      if (!regex.test(sheetUrl)) {
        setError({ ...error, sheet_url: "Please enter valid sheet link" });
        return false;
      }
      if (!dSObj.current?.sheet_name) {
        setError({ ...error, sheet_name: "Please enter valid sheet name" });
        return;
      }
    }
    setLoading(true);
    let count =
      dataSource?.filter(
        (o1) =>
          o1?.transaction_systems === dSObj.current?.transaction_systems?.uuid
      )?.length ?? 0;
    if (!dSObj.current?.ds?.title?.trim()) {
      dSObj.current = {
        ...dSObj.current,
        ds: {
          ...dSObj.current?.ds,
          title: `${dSObj.current?.card?.name} ${
            count > 0 ? `(${count})` : ""
          }`,
        },
      };
    }

    if (dSObj.current?.modalType === "Edit" && isDataUpdated.current) {
      afterDsUpdated(integration_id);
    }
    if (dSObj.current?.modalType === "Add") {
      if (dSObj.current?.card?.name === "Excel import") {
        setIsSheetImportFlow(true);
        return;
      }
      if (!integration_id) {
        createNewDS();
      } else {
        afterDsCreate(integration_id);
      }
    }
  };

  const afterDsCreate = async (integration_id) => {
    let obj = {};
    if (showApiUrl) {
      obj.app_url = dSObj.current?.app_url;
    }
    if (showApiKey) {
      obj.api_key = dSObj.current?.api_key;
    }
    if (showApiClient) {
      obj.api_client = dSObj.current?.api_client;
    }
    if (showApiSecret) {
      obj.api_secret = dSObj.current?.api_secret;
    }
    if (showFromDate) {
      obj.from_date = dSObj.current?.from_date || null;
    }
    if (showIntegrationState) {
      obj.transaction_type = dSObj.current?.transaction_type;
    }
    if (showSheetId) {
      const matchResult = dSObj.current?.sheet_url?.match(/\/d\/(.+)\//);
      const sheet_id = matchResult ? matchResult?.[1] : null;
      if (!sheet_id) {
        enqueueSnackbar(t("Invalid sheet url"), {
          variant: "error",
          autoHideDuration: 5000,
          preventDuplicate: true,
        });
        setLoading(false);
        return;
      }
      obj.sheet_id = sheet_id;
      obj.sheet_name = dSObj.current?.sheet_name;
    }
    if (showSettings) {
      obj.data_overriding = dSObj.current?.data_overriding ?? 1;
      obj.data_importing = dSObj.current?.data_importing ?? 1;
      obj.error_handling = dSObj.current?.error_handling ?? 1;
      obj.fallback_state = dSObj.current?.fallback_state ?? "Open";
      obj.fallback_category =
        dSObj.current?.fallback_category ?? "Uncategorized";
      obj.fallback_scenario = dSObj.current?.fallback_scenario ?? "Base";
    }
    if (dSObj.current?.card?.name === "Excel import") {
      obj.headers = JSON.stringify(dSObj.current?.headers) ?? {};
      obj.sheet_name = dSObj.current?.sheet_name ?? "";
      obj.uploaded_file = dSObj.current?.uploaded_file ?? null;
    }
    addApiKey(integration_id, obj);
  };

  const afterDsUpdated = async (integration_id) => {
    setLoading(true);
    try {
      updateDSByID(integration_id, {
        title: dSObj.current?.ds?.title,
        currency: dSObj.current?.ds?.currency,
      });
      let obj = {};
      if (showApiUrl) {
        obj.app_url = dSObj.current?.app_url;
      }
      if (showApiKey) {
        obj.api_key = dSObj.current?.api_key;
      }
      if (showApiClient) {
        obj.api_client = dSObj.current?.api_client;
      }
      if (showApiSecret) {
        obj.api_secret = dSObj.current?.api_secret;
      }
      if (showFromDate) {
        obj.from_date = dSObj.current?.from_date || null;
      }
      if (showSheetId) {
        const matchResult = dSObj.current?.sheet_url?.match(/\/d\/(.+)\//);
        const sheet_id = matchResult ? matchResult?.[1] : null;
        if (!sheet_id) {
          enqueueSnackbar(t("Invalid sheet url"), {
            variant: "error",
            autoHideDuration: 5000,
            preventDuplicate: true,
          });
          setLoading(false);
          return;
        }
        obj.sheet_id = sheet_id;
        obj.sheet_name = dSObj.current?.sheet_name;
      }
      if (showSettings) {
        obj.data_overriding = dSObj.current?.data_overriding ?? 1;
        obj.data_importing = dSObj.current?.data_importing ?? 1;
        obj.error_handling = dSObj.current?.error_handling ?? 1;
        obj.fallback_state = dSObj.current?.fallback_state ?? "Open";
        obj.fallback_category =
          dSObj.current?.fallback_category ?? "Uncategorized";
        obj.fallback_scenario = dSObj.current?.fallback_scenario ?? "Base";
      }
      if (showIntegrationState) {
        obj.transaction_type = dSObj.current?.transaction_type;
      }
      if (dSObj.current?.card?.name === "Excel import") {
        obj.headers = JSON.stringify(dSObj.current?.headers) ?? {};
        obj.sheet_name = dSObj.current?.sheet_name ?? "";
        obj.uploaded_file = dSObj.current?.uploaded_file ?? null;
      }
      // if (dSObj.current?.card?.name === "Google Sheets API") {
      //   addApiKey(integration_id, obj);
      //   return;
      // }
      updateApiKey(integration_id, obj);
    } catch (error) {}
  };

  const handleEditApi = (e) => {
    let { name, value } = e.target;
    isDataUpdated.current = true;
    setItem({ ...item, [name]: value });
    setError(null);
    dSObj.current = { ...dSObj.current, [name]: value };
  };

  const onChangeCurrency = (e, value) => {
    setError(null);
    isDataUpdated.current = true;
    setItem({ ...item, currency: value?.code });
    dSObj.current = {
      ...dSObj.current,
      ds: { ...dSObj.current?.ds, currency: value?.code },
    };
  };

  const onChangeGoogleSheetSettings = (name, value) => {
    setError(null);
    isDataUpdated.current = true;
    setItem({ ...item, [name]: value?.value });
    dSObj.current = {
      ...dSObj.current,
      [name]: value?.value,
    };
  };

  const onChangeState = (e, value) => {
    setError(null);
    isDataUpdated.current = true;
    setItem({ ...item, transaction_type: value?.uuid });
    dSObj.current = {
      ...dSObj.current,
      transaction_type: value?.uuid,
    };
  };

  const onChangeFromDate = (e) => {
    let { value } = e.target;
    if (value !== "Invalid Date") {
      setError(null);
      isDataUpdated.current = true;
      let from_date = value ? format(new Date(value), "yyyy-MM-dd") : null;
      setError({ ...error, from_date: "" });
      setItem({
        ...item,
        from_date,
      });
      dSObj.current = {
        ...dSObj.current,
        from_date: from_date,
      };
    }
  };

  const handleChangeGSheetHeaderMapping = () => {
    const headers = dSObj.current?.api_key?.headers;
    const headersSetByUser =
      headers && Object.keys(headers)?.length > 0
        ? Object.keys(headers).reduce((acc, key) => {
            const value = headers[key];
            if (value) {
              acc[value] = key;
            }
            return acc;
          }, {})
        : null;
    getSheetHeaders(dSObj.current?.ds?.uuid, headersSetByUser);
  };

  const handleEditDs = (e) => {
    const { name, value } = e.target;
    let obj = { [name]: value };
    setError(null);
    setItem({ ...item, ...obj });
    isDataUpdated.current = true;
    dSObj.current = {
      ...dSObj.current,
      ds: { ...dSObj.current?.ds, ...obj },
    };
  };

  const onCloseExcelForm = () => {
    setLoading(false);
    setIsSheetImportFlow(false);
  };

  const onClickLink = () => {
    finbanInfoRef.current?.open({
      url: dSObj.current?.card?.infoLink,
    });
  };

  //lifecycle
  useEffect(() => {
    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    if (open) {
      isDataUpdated.current = false;
      if (dSObj.current?.modalType === "Edit" && dSObj.current?.ds?.uuid) {
        getApiKey(dSObj.current?.ds?.uuid, true);
      } else {
        let count =
          dataSource?.filter(
            (o1) =>
              o1?.transaction_system ===
              dSObj.current?.transaction_systems?.uuid
          )?.length ?? 0;
        dSObj.current = {
          ...dSObj.current,
          from_date: format(
            startOfMonth(subMonths(new Date(), 3)),
            "yyyy-MM-dd"
          ),
          ds: {
            ...dSObj.current?.ds,
            title: `${
              dSObj.current?.card?.name === "Lexoffice"
                ? "lexoffice"
                : dSObj.current?.card?.name
            } ${count > 0 ? `(${count})` : ""}`,
            currency: clickable ? dataSetData?.currency : null,
          },
        };
        setItem({
          ...item,
          title: dSObj.current?.ds?.title,
          currency: dSObj.current?.ds?.currency,
          from_date: dSObj.current?.from_date,
          transaction_type: 1,
        });
      }
    }
  }, [open, cell]);

  return (
    <>
      <Dialog
        open={open}
        onClose={onClickOutside}
        TransitionComponent={Fade}
        PaperProps={{
          style: {
            minWidth: "50rem",
            maxWidth: "none",
            width: "auto",
          },
        }}
        sx={{
          zIndex: 1303,
          "& button": {
            textTransform: "capitalize",
          },
        }}
      >
        <DSConnections ref={dsRef} />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: "5rem",
            backgroundColor: Color.HeaderBG,
          }}
        >
          <Stack direction="row" sx={{ alignItems: "center" }}>
            {dSObj.current?.card?.img ? (
              <CardMedia
                component="img"
                sx={{
                  width: ImageWidth,
                  height: "2.5rem",
                  objectFit: "contain",
                  ml: "2rem",
                }}
                image={dSObj.current?.card?.img}
                alt="Logo"
              />
            ) : (
              <Icon
                icon={dSObj.current?.card?.icon}
                fontSize={"1.3rem"}
                color="inherit"
                style={{
                  width: "fit-content",
                  "& svg": {
                    width: "6rem",
                    height: "2rem",
                  },
                }}
              ></Icon>
            )}{" "}
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: 500,
                ml: "1rem",
                color: Color.tailwind.purple[950],
              }}
            >
              {tooltip}
            </Typography>
          </Stack>
          <Stack direction="row" sx={{ alignItems: "center" }}>
            {dSObj.current?.card?.exampleLink ? (
              <Link
                variant="subtitle2"
                href={dSObj.current?.card?.exampleLink}
                target="_blank"
                sx={{
                  fontWeight: 500,
                }}
              >
                {t("Example Sheet")}
              </Link>
            ) : null}
            {dSObj.current?.card?.infoLink ? (
              <TipsAndUpdatesTwoToneIcon
                onClick={onClickLink}
                sx={{
                  color: theme.palette.color.amber[300],
                  cursor: "pointer",
                  mx: "1rem",
                  "&:hover": {
                    color: theme.palette.color.amber[500],
                  },
                }}
              />
            ) : null}
          </Stack>
        </Box>

        <DialogContent
          sx={{
            minHeight: "24rem",
            backgroundColor: Color.BodyBG,
            ...theme.thinScrollBar,
          }}
        >
          <Box
            display="flex"
            alignItems={"center"}
            width="100%"
            pl={"2.5rem"}
            mb="1rem"
          >
            <TitleInput
              label={t("Title")}
              name="title"
              value={item?.title || ""}
              onChange={handleEditDs}
              helperText={error?.title}
              error={Boolean(error?.title)}
              hideTitle
              likeGoogle
              variant="filled"
              disabled={disabled}
            />
          </Box>
          {showApiClient ? (
            <Box display="flex" alignItems={"center"} width={"100%"} mb="1rem">
              <ApiIcon sx={{ mr: "1rem", mb: "1rem" }} />
              <TitleInput
                label={t("API Client")}
                name="api_client"
                placeholder="api client add here..."
                value={item?.api_client || ""}
                onChange={handleEditApi}
                helperText={error?.api_client}
                error={Boolean(error?.api_client)}
                autoFocus
                hideTitle
                likeGoogle
                variant="filled"
                disabled={disabled}
              />
            </Box>
          ) : null}
          {showApiSecret ? (
            <Box display="flex" alignItems={"center"} width={"100%"} mb="1rem">
              <ApiIcon sx={{ mr: "1rem", mb: "1rem" }} />
              <TitleInput
                label={t("API Secret")}
                name="api_secret"
                placeholder="api secret add here..."
                value={item?.api_secret || ""}
                onChange={handleEditApi}
                helperText={error?.api_secret}
                error={Boolean(error?.api_secret)}
                autoFocus
                hideTitle
                likeGoogle
                variant="filled"
                disabled={disabled}
              />
            </Box>
          ) : null}
          {showApiKey ? (
            <Box display="flex" alignItems={"center"} width={"100%"} mb="1rem">
              <ApiIcon sx={{ mr: "1rem", mb: "1rem" }} />
              <TitleInput
                label={t("API key")}
                name="api_key"
                placeholder="api key add here..."
                value={item?.api_key || ""}
                onChange={handleEditApi}
                helperText={error?.api_key}
                error={Boolean(error?.api_key)}
                autoFocus
                hideTitle
                likeGoogle
                variant="filled"
                disabled={disabled}
              />
            </Box>
          ) : null}
          {showApiUrl ? (
            <Box display="flex" alignItems={"center"} width={"100%"} mb="1rem">
              <HttpIcon sx={{ mr: "1rem", mb: "1rem" }} />
              <TitleInput
                label={t("App-URL")}
                name="app_url"
                placeholder="app url add here..."
                value={item?.app_url || ""}
                onChange={handleEditApi}
                helperText={error?.app_url}
                error={Boolean(error?.app_url)}
                autoFocus
                hideTitle
                likeGoogle
                variant="filled"
                disabled={disabled}
              />
            </Box>
          ) : null}
          {showSheetId ? (
            <>
              <Box display="flex" alignItems={"start"} width={"100%"} mb="1rem">
                <InsertLinkIcon
                  sx={{ mr: "1rem", mt: item?.sheet_url ? "0.5rem" : "1rem" }}
                />
                <TitleInput
                  label={t("Sheet Link")}
                  name="sheet_url"
                  placeholder={t("Add Google Sheet Link here")}
                  value={item?.sheet_url || ""}
                  onChange={handleEditApi}
                  helperText={error?.sheet_url}
                  error={Boolean(error?.sheet_url)}
                  autoFocus
                  hideTitle
                  likeGoogle
                  variant="filled"
                  disabled={disabled}
                />
              </Box>
              <Box display="flex" alignItems={"start"} width={"100%"} mb="1rem">
                <InsertDriveFileOutlinedIcon
                  sx={{
                    mr: "1rem",
                    mt: item?.sheet_name ? "0.5rem" : "1rem",
                  }}
                />
                <TitleInput
                  label={t("Sheet Name")}
                  name="sheet_name"
                  placeholder={t("Add Google sheet name here")}
                  value={item?.sheet_name || ""}
                  onChange={handleEditApi}
                  helperText={error?.sheet_name}
                  error={Boolean(error?.sheet_name)}
                  autoFocus
                  hideTitle
                  likeGoogle
                  variant="filled"
                  disabled={disabled}
                />
              </Box>
            </>
          ) : null}
          <Box display="flex" alignItems={"start"} width="100%" mb="1rem">
            <BsCurrencyExchange
              style={{
                fontSize: "1.5rem",
                marginRight: "1rem",
                marginTop: item?.currency ? "0.5rem" : "1rem",
              }}
            />
            <DropDown
              disableClearable={true}
              label={t("Currency")}
              hideTitle
              likeGoogle
              variant="filled"
              helperText={error?.currency}
              error={Boolean(error?.currency)}
              disabled={dSObj.current?.modalType === "Edit" || !!clickable}
              value={
                currencies.find((o1) => o1.code === item?.currency) ?? null
              }
              onChange={onChangeCurrency}
              options={currencies}
              getOptionLabel={(option) =>
                option.symbol + " " + option.name ?? ""
              }
              renderOption={(option) => option.symbol + " " + option.name ?? ""}
            />
          </Box>
          {showSettings ? (
            <>
              <Divider sx={{ width: "100%", mt: "-1rem", mb: "1rem" }} />
              <Box
                display="flex"
                alignItems={"flex-start"}
                width="60%"
                mb="1rem"
              >
                <RuleOutlinedIcon sx={{ mr: "1rem", mt: "0.5rem" }} />
                <DropDown
                  disableClearable={true}
                  label={t("Data Overriding")}
                  hideTitle
                  likeGoogle
                  variant="filled"
                  helperText={error?.data_overriding}
                  error={Boolean(error?.data_overriding)}
                  // disabled={true}
                  value={
                    DataOverridingOptions.find(
                      (o1) => o1.value === item?.data_overriding
                    ) ?? DataOverridingOptions[0]
                  }
                  onChange={(e, value) =>
                    onChangeGoogleSheetSettings("data_overriding", value)
                  }
                  options={DataOverridingOptions}
                  getOptionLabel={(option) => option.label ?? ""}
                  renderOption={(option) => option.label ?? ""}
                />
                <InfoView tooltip={"Data Overriding tooltip"} />
              </Box>
              <Box
                display="flex"
                alignItems={"flex-start"}
                width="60%"
                mb="1rem"
              >
                <ImportExportOutlinedIcon sx={{ mr: "1rem", mt: "0.5rem" }} />
                <DropDown
                  disableClearable={true}
                  label={t("Data Importing")}
                  hideTitle
                  likeGoogle
                  variant="filled"
                  helperText={error?.data_importing}
                  error={Boolean(error?.data_importing)}
                  // disabled={true}
                  value={
                    DataImportingOptions.find(
                      (o1) => o1.value === item?.data_importing
                    ) ?? DataImportingOptions[0]
                  }
                  onChange={(e, value) =>
                    onChangeGoogleSheetSettings("data_importing", value)
                  }
                  options={DataImportingOptions}
                  getOptionLabel={(option) => option.label ?? ""}
                  renderOption={(option) => option.label ?? ""}
                />
                <InfoView tooltip={"data_importing_tooltip"} />
              </Box>
              <Box
                display="flex"
                alignItems={"flex-start"}
                width="60%"
                mb="1rem"
              >
                <ErrorOutlinedIcon sx={{ mr: "1rem", mt: "0.5rem" }} />
                <DropDown
                  disableClearable={true}
                  label={t("Import and Error handling")}
                  hideTitle
                  likeGoogle
                  variant="filled"
                  helperText={error?.error_handling}
                  error={Boolean(error?.error_handling)}
                  // disabled={true}
                  value={
                    ErrorHandlingOptions.find(
                      (o1) => o1.value === item?.error_handling
                    ) ?? ErrorHandlingOptions[0]
                  }
                  onChange={(e, value) =>
                    onChangeGoogleSheetSettings("error_handling", value)
                  }
                  options={ErrorHandlingOptions}
                  getOptionLabel={(option) => option.label ?? ""}
                  renderOption={(option) => option.label ?? ""}
                />
                <InfoView tooltip={"import and Error handling tooltip"} />
              </Box>
              <Box
                display="flex"
                alignItems={"flex-start"}
                width="60%"
                mb="1rem"
              >
                <TbCircles
                  style={{
                    marginRight: "1.25rem",
                    marginTop: "0.5rem",
                    fontSize: "1.25rem",
                  }}
                />
                <DropDown
                  disableClearable={true}
                  label={t("State fallback")}
                  hideTitle
                  likeGoogle
                  variant="filled"
                  helperText={error?.fallback_state}
                  error={Boolean(error?.fallback_state)}
                  disabled={true}
                  value={t("Open")}
                  onChange={handleEditApi}
                  options={[]} //Need to add the options later
                  getOptionLabel={(option) =>
                    option.symbol + " " + option.name ?? ""
                  }
                  renderOption={(option) =>
                    option.symbol + " " + option.name ?? ""
                  }
                />
                <InfoView tooltip={"State fallback tooltip"} />
              </Box>
              <Box
                display="flex"
                alignItems={"flex-start"}
                width="60%"
                mb="1rem"
              >
                <LocalOfferIcon
                  sx={{ mr: "1.25rem", mt: "0.5rem", fontSize: "1.25rem" }}
                />
                <DropDown
                  disableClearable={true}
                  label={t("Category fallback")}
                  hideTitle
                  likeGoogle
                  variant="filled"
                  helperText={error?.fallback_category}
                  error={Boolean(error?.fallback_category)}
                  disabled={true}
                  value={t("Uncategorized")}
                  onChange={handleEditApi}
                  options={[]} //Need to add the options later
                  getOptionLabel={(option) =>
                    option.symbol + " " + option.name ?? ""
                  }
                  renderOption={(option) =>
                    option.symbol + " " + option.name ?? ""
                  }
                />
                <InfoView tooltip={"Category fallback tooltip"} />
              </Box>
              <Box
                display="flex"
                alignItems={"flex-start"}
                width="60%"
                mb="1rem"
              >
                <TbChartAreaLineFilled
                  style={{
                    marginRight: "1.25rem",
                    marginTop: "0.5rem",
                    fontSize: "1.25rem",
                  }}
                />
                <DropDown
                  disableClearable={true}
                  label={t("Scenario fallback")}
                  hideTitle
                  likeGoogle
                  variant="filled"
                  helperText={error?.fallback_scenario}
                  error={Boolean(error?.fallback_scenario)}
                  disabled={true}
                  value={t("Base")}
                  onChange={handleEditApi}
                  options={[]} //Need to add the options later
                  getOptionLabel={(option) =>
                    option.symbol + " " + option.name ?? ""
                  }
                  renderOption={(option) =>
                    option.symbol + " " + option.name ?? ""
                  }
                />
                <InfoView tooltip={"Scenario fallback tooltip"} />
              </Box>
            </>
          ) : null}

          {showFromDate ? (
            <Box display="flex" alignItems={"center"} width="35%" mb="1rem">
              <CalendarMonthIcon sx={{ mr: "1rem" }} />
              <DateInput
                required
                hideTitle
                name="from_date"
                id="date"
                value={item?.from_date ?? ""}
                onChange={onChangeFromDate}
                label={t("Import Objects from this date")}
                tooltip={t("Import Objects from this date")}
                likeGoogle
                variant="filled"
                helperText={error?.from_date}
                error={Boolean(error?.from_date)}
                disabled={disabled}
              />
            </Box>
          ) : null}
          {showIntegrationState ? (
            <Box display="flex" alignItems={"center"} width="100%" mb="1rem">
              <TbCircles style={{ marginRight: "1rem", fontSize: "1.25rem" }} />
              <DropDown
                disableClearable={true}
                label={t("Transaction State")}
                hideTitle
                likeGoogle
                variant="filled"
                helperText={error?.transaction_type}
                error={Boolean(error?.transaction_type)}
                disabled={dSObj.current?.modalType === "Edit"}
                value={
                  Constant.StateSource?.find(
                    (o1) => o1.uuid === item?.transaction_type
                  ) ?? null
                }
                onChange={onChangeState}
                options={Constant.StateSource || []}
              />
            </Box>
          ) : null}

          {(isGsheetHeaderMappingNotAdded ||
            (showSheetId && dSObj.current?.modalType === "Edit")) && (
            <TailwindButton
              text={t("Change Header Mapping")}
              onClick={handleChangeGSheetHeaderMapping}
              loading={loading}
              sx={{ ml: "3rem" }}
            />
          )}
          {dSObj.current?.modalType === "Edit" &&
            dSObj.current?.card?.name === "Excel import" && (
              <TailwindButton
                text={t("Change Excel Sheet")}
                onClick={() => setIsSheetImportFlow(true)}
                loading={loading}
                sx={{ ml: "3rem" }}
              />
            )}
        </DialogContent>
        <DialogActions sx={{ backgroundColor: Color.FooterBG, p: "1.5rem" }}>
          <TailwindButton type="cancel" text={t("Cancel")} onClick={onClose} />
          <TailwindButton
            text={t(
              dSObj.current?.modalType === "Edit"
                ? "Save"
                : showSettings
                  ? "Next"
                  : "Add"
            )}
            loading={loading}
            disable={
              dSObj.current?.modalType === "Edit" && !isDataUpdated.current
            }
            onClick={loading ? undefined : handleClickAdd}
          />
        </DialogActions>
      </Dialog>
      <GoogleSheetHeaderMappingDialogView
        open={isGSheetHeaderMappingOpen}
        onClose={handleOnCloseGSheetHeaderMapping}
        updateGSheetsHeaders={updateGSheetsHeaders}
      />
      {isSheetImportFlow ? (
        <SheetComponent
          open={isSheetImportFlow}
          onClose={onCloseExcelForm}
          handleFinalSubmit={handleFinalSubmitOfExcelFlow}
        />
      ) : null}
    </>
  );
};

export default TransactionSystemDialogView;

const InfoView = ({ tooltip }) => {
  const { t } = useTranslation();
  return (
    <Tooltip arrow title={t(tooltip)} placement="top">
      <HelpOutlineIcon
        sx={{
          color: theme.palette.primary.main,
          cursor: "pointer",
          fontSize: "1.1rem",
          ml: "1rem",
          mt: "0.5rem",
        }}
      />
    </Tooltip>
  );
};
