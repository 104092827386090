import { useCallback, useEffect, useState, useMemo, useRef } from "react";
import { TbChartAreaLineFilled, TbCircles, TbPlug } from "react-icons/tb";
import { RiArrowUpDownLine, RiFolderChartLine } from "react-icons/ri";
import { Box, Button, Divider, useTheme } from "@mui/material";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { MdOutlineDateRange } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { FiLayers } from "react-icons/fi";
import _ from "underscore";

import CategoryFilterView from "../../../../components/Overlay/ThemeFilter/Component/CategoryFilterView";
import SearchFilterView from "../../../../components/Overlay/ThemeFilter/Component/SearchFilterView";
import DateRangePicker from "../../../../components/Overlay/ThemeFilter/Component/DateRangePicker";
import AddFilterPopOver from "../../../../components/Overlay/ThemeFilter/AddFilterPopover";
import AddFilterView from "../../../../components/Overlay/ThemeFilter/AddFilterView";
import TransactionViewSettingPopOver from "./TransactionViewSettingPopOver";
import HelpOverlay from "../../../../components/HelpOverlay.js/index.js";
import useDebounce from "../../../../hooks/3-useDebounce/useDebounce";
import ThemeFilter from "../../../../components/Overlay/ThemeFilter";
import Toggle from "../../../../components/ToggleGroupeView/Toggle";
import { updateFilters } from "../../../../store/slices/global";
import { Constant, Images } from "../../../../Helper";
import initialData from "../../../../Helper/data";
import store from "../../../../store";

const typeOptions = [
  { uuid: "Income", title: "Income", color: "green" },
  { uuid: "Expense", title: "Expense", color: "red" },
];
const sourceOptions = [
  { uuid: "Option_Planning", title: "Option_Planning" },
  { uuid: "Option_Api", title: "Option_Api" },
  { uuid: "Option_Import", title: "Option_Import" },
  { uuid: "Option_External", title: "Option_External" },
];
const dateOptions = [
  {
    value: 1,
    label: "Due",
  },
  {
    value: 2,
    label: "Invoiced",
  },
];
const ListHeader = ({
  hideState = false,
  hideScenario = false,
  hideCostUnit = false,
  hideCategory = false,
  hideSource = false,
  hideSeqType = false,
  optionsType = null,
  width,
  tab,
}) => {
  const dispatch = useDispatch();
  const isFilterOpen = useRef(false);

  //redux state
  const appliedFilterlist = useSelector(
    (state) => state.globalSlice.appliedFilterlist
  );

  //state
  const [anchorEl, setAnchorEl] = useState(null);
  const [filtered, setFiltered] = useState([]);
  const [isIncludedInFilterList, setIsIncludedInFilterList] = useState([]);

  //function
  const onClose = (_selectedIds) => {
    isFilterOpen.current = false;
    setIsIncludedInFilterList(_selectedIds);
    setAnchorEl(null);
    let remove = {};
    if (
      (appliedFilterlist?.list?.start_date ||
        appliedFilterlist?.list?.end_date) &&
      !_selectedIds?.includes("date")
    ) {
      remove.start_date = null;
      remove.end_date = null;
    }
    if (
      !hideState &&
      !optionsType &&
      appliedFilterlist?.list?.selectedStates?.length > 0 &&
      !_selectedIds?.includes("state")
    ) {
      remove.selectedStates = [];
    }
    if (
      !hideState &&
      optionsType === "Open" &&
      appliedFilterlist?.list?.selectedOpenStates?.length > 0 &&
      !_selectedIds?.includes("state_open")
    ) {
      remove.selectedOpenStates = [];
    }
    if (
      !hideState &&
      optionsType === "Bank" &&
      appliedFilterlist?.list?.selectedBankStates?.length > 0 &&
      !_selectedIds?.includes("state_bank")
    ) {
      remove.selectedBankStates = [];
    }
    if (
      !hideScenario &&
      appliedFilterlist?.list?.selectedScenarios?.length > 0 &&
      !_selectedIds?.includes("scenario")
    ) {
      remove.selectedScenarios = [];
    }
    if (
      !hideCostUnit &&
      appliedFilterlist?.list?.selectedCostUnits?.length > 0 &&
      !_selectedIds?.includes("cost_unit")
    ) {
      remove.selectedCostUnits = [];
    }
    if (
      !hideCategory &&
      appliedFilterlist?.list?.selectedCategory?.length > 0 &&
      !_selectedIds?.includes("category")
    ) {
      remove.selectedCategory = [];
    }
    if (
      appliedFilterlist?.list?.typeName?.length !== 2 &&
      !_selectedIds?.includes("typeName")
    ) {
      remove.typeName = ["Expense", "Income"];
    }
    if (
      !hideSource &&
      appliedFilterlist?.list?.source?.length !== 4 &&
      !_selectedIds?.includes("source")
    ) {
      remove.source = Constant.DefaultSource;
    }
    if (
      !hideSeqType &&
      appliedFilterlist?.list?.recurring_type?.length > 0 &&
      !_selectedIds?.includes("recurring_type")
    ) {
      remove.recurring_type = [];
    }
    if (
      appliedFilterlist?.list?.selectedDataSource?.length > 0 &&
      !_selectedIds?.includes("ds")
    ) {
      remove.ds = [];
    }
    if (Object?.keys(remove)?.length > 0) {
      updateFilterObj(remove);
    }
  };

  const updateFilterObj = (_obj) => {
    let obj = {
      parent_key: "list",
      obj: _obj,
    };
    dispatch(updateFilters(obj));
  };

  const onClickFilter = useCallback((e) => {
    isFilterOpen.current = true;
    setAnchorEl(e.currentTarget);
  }, []);

  const removeFilter = useCallback((key) => {
    setIsIncludedInFilterList((prev) => prev?.filter((item) => item !== key));
  }, []);

  useDebounce(
    () => {
      if (!isFilterOpen.current && appliedFilterlist?.list) {
        let array = [];
        let included_array = [...isIncludedInFilterList];

        if (
          appliedFilterlist?.list?.start_date ||
          appliedFilterlist?.list?.end_date
        ) {
          array.push("date");
          included_array.push("date");
        }
        if (appliedFilterlist?.list?.searchText?.length > 0) {
          array.push("search");
          included_array.push("search");
        }
        if (
          appliedFilterlist?.list?.selectedStates?.length > 0 &&
          !hideState &&
          !optionsType
        ) {
          array.push("state");
          included_array.push("state");
        }
        if (
          appliedFilterlist?.list?.selectedOpenStates?.length > 0 &&
          !hideState &&
          optionsType === "Open"
        ) {
          array.push("state_open");
          included_array.push("state_open");
        }
        if (
          appliedFilterlist?.list?.selectedBankStates?.length > 0 &&
          !hideState &&
          optionsType === "Bank"
        ) {
          array.push("state_bank");
          included_array.push("state_bank");
        }
        if (
          appliedFilterlist?.list?.selectedScenarios?.length > 0 &&
          !hideScenario
        ) {
          array.push("scenario");
          included_array.push("scenario");
        }
        if (
          appliedFilterlist?.list?.selectedCostUnits?.length > 0 &&
          !hideCostUnit
        ) {
          array.push("cost_unit");
          included_array.push("cost_unit");
        }
        if (
          appliedFilterlist?.list?.selectedCategory?.length > 0 &&
          !hideCategory
        ) {
          array.push("category");
          included_array.push("category");
        }

        if (appliedFilterlist?.list?.typeName?.length !== 2) {
          array.push("typeName");
          included_array.push("typeName");
        }
        if (appliedFilterlist?.list?.source?.length !== 4 && !hideSource) {
          array.push("source");
          included_array.push("source");
        }
        if (appliedFilterlist?.list?.selectedDataSource?.length > 0) {
          array.push("ds");
          included_array.push("ds");
        }
        if (
          appliedFilterlist?.list?.recurring_type?.length > 0 &&
          !hideSeqType
        ) {
          array.push("recurring_type");
          included_array.push("recurring_type");
        }

        included_array = Array.from(new Set(included_array));
        setFiltered(array);
        setIsIncludedInFilterList(included_array);
      }
    },
    500,
    [
      appliedFilterlist?.list,
      hideCategory,
      hideCostUnit,
      hideScenario,
      hideSource,
      hideSeqType,
      hideState,
      optionsType,
      tab,
    ],
    true
  );

  const FilterOptions = useMemo(
    () => [
      {
        uuid: "date",
        title: "add_date_filter",
        icon: <MdOutlineDateRange />,
        hide: false,
      },
      {
        uuid: "state",
        title: "add_state_filter",
        icon: <TbCircles />,
        hide: hideState || (!hideState && optionsType),
      },
      {
        uuid: "state_open",
        title: "add_state_filter",
        icon: <TbCircles />,
        hide: hideState || (!hideState && optionsType !== "Open"),
      },
      {
        uuid: "state_bank",
        title: "add_state_filter",
        icon: <TbCircles />,
        hide: hideState || (!hideState && optionsType !== "state_bank"),
      },
      {
        uuid: "scenario",
        title: "add_scenario_filter",
        icon: <TbChartAreaLineFilled />,
        hide: hideScenario,
      },
      {
        uuid: "cost_unit",
        title: "add_cost_unit_filter",
        icon: <RiFolderChartLine />,
        hide: hideCostUnit,
      },
      {
        uuid: "category",
        title: "add_category_filter",
        icon: <LocalOfferIcon />,
        hide: hideCategory,
      },
      {
        uuid: "typeName",
        title: "add_typeName_filter",
        icon: <RiArrowUpDownLine />,
        hide: false,
      },
      {
        uuid: "source",
        title: "add_source_filter",
        icon: <FiLayers />,
        hide: hideSource,
      },
      {
        uuid: "ds",
        title: "add_ds_filter",
        icon: <TbPlug />,
        hide: hideSource,
      },
      {
        uuid: "recurring_type",
        title: "add_recurring_type_filter",
        icon: <TbPlug />,
        hide: hideSeqType,
      },
    ],
    [
      hideCategory,
      hideCostUnit,
      hideSeqType,
      hideScenario,
      hideSource,
      hideState,
      optionsType,
    ]
  );

  //render functions
  const DetailsView = useMemo(() => {
    return (
      <div
        style={{
          display: "inline-flex",
          alignItems: "start",
        }}
      >
        <BulkActionView tab={tab} />
        <SearchView />
        <Divider
          orientation="vertical"
          flexItem
          sx={{
            height: "1.2rem",
            opacity: "0.6",
            alignSelf: "center",
            ml: "0.75rem",
          }}
        />
        <Box
          display="inline-flex"
          alignItems="center"
          justifyContent={"flex-start"}
          width="fit-content"
          sx={{
            minWidth: "25rem",
            transition: `all 0.3s ease-in-out`,
            zIndex: 2,
            gap: "0.5rem",
            flexWrap: "wrap",
          }}
        >
          {isIncludedInFilterList?.length > 0 ? null : (
            <AddFilterView
              isIncludedInFilterList={isIncludedInFilterList}
              onClick={onClickFilter}
            />
          )}

          {isIncludedInFilterList?.map((item) => {
            if (item === "date") {
              return (
                <DateFilter
                  key="date"
                  filterKey="date"
                  removeFilter={removeFilter}
                />
              );
            }

            if (item === "state") {
              return (
                <StateFilter
                  key="state"
                  type="list"
                  isFilterOpen={isFilterOpen}
                  filterKey="state"
                  removeFilter={removeFilter}
                />
              );
            }
            if (item === "state_open") {
              return (
                <StateFilter
                  key="state_open"
                  type="list"
                  isFilterOpen={isFilterOpen}
                  optionsType={optionsType}
                  _key={"selectedOpenStates"}
                  filterKey="state_open"
                  removeFilter={removeFilter}
                />
              );
            }
            if (item === "state_bank") {
              return (
                <StateFilter
                  key="state_bank"
                  type="list"
                  optionsType={optionsType}
                  isFilterOpen={isFilterOpen}
                  _key={"selectedBankStates"}
                  filterKey="state_bank"
                  removeFilter={removeFilter}
                />
              );
            }
            if (item === "scenario") {
              return (
                <ScenarioFilter
                  key="scenario"
                  type="list"
                  isFilterOpen={isFilterOpen}
                  filterKey="scenario"
                  removeFilter={removeFilter}
                />
              );
            }
            if (item === "cost_unit") {
              return (
                <CostUnitFilter
                  key="cost_unit"
                  type="list"
                  isFilterOpen={isFilterOpen}
                  filterKey="cost_unit"
                  removeFilter={removeFilter}
                />
              );
            }
            if (item === "category") {
              return (
                <CategoryFilter
                  key="category"
                  type="list"
                  isFilterOpen={isFilterOpen}
                  filterKey="category"
                  removeFilter={removeFilter}
                />
              );
            }
            if (item === "typeName") {
              return (
                <TypeFilter
                  key="typeName"
                  type="list"
                  isFilterOpen={isFilterOpen}
                  filterKey="typeName"
                  removeFilter={removeFilter}
                />
              );
            }
            if (item === "source") {
              return (
                <SourceFilter
                  key={"source"}
                  isFilterOpen={isFilterOpen}
                  filterKey="source"
                  removeFilter={removeFilter}
                />
              );
            }
            if (item === "ds") {
              return (
                <DsFilter
                  key="ds"
                  isFilterOpen={isFilterOpen}
                  filterKey="ds"
                  removeFilter={removeFilter}
                />
              );
            }
            if (item === "recurring_type") {
              return (
                <RecurringTypeFilter
                  key={"recurring_type"}
                  isFilterOpen={isFilterOpen}
                  filterKey="recurring_type"
                  removeFilter={removeFilter}
                />
              );
            }
            return null;
          })}

          {isIncludedInFilterList?.length > 0 ? (
            <AddFilterView
              isIncludedInFilterList={isIncludedInFilterList}
              onClick={onClickFilter}
            />
          ) : null}
          <ResetView
            filtered={filtered}
            setIsIncludedInFilterList={setIsIncludedInFilterList}
          />
        </Box>
      </div>
    );
  }, [
    tab,
    isIncludedInFilterList,
    onClickFilter,
    filtered,
    optionsType,
    removeFilter,
  ]);

  return (
    <Box
      sx={{
        // width: "fit-content",
        width: width,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      {DetailsView}
      {!!anchorEl ? (
        <AddFilterPopOver
          anchorEl={anchorEl}
          onClose={onClose}
          selectedIds={isIncludedInFilterList}
          options={FilterOptions}
        />
      ) : null}
      <HelpOverlay
        text={"List View"}
        path={"list"}
        tourKey="list"
        hideHeader
        image={Images.list_insight}
        wrapperStyle={{ mr: 0 }}
      />
    </Box>
  );
};
export default ListHeader;

const SearchView = ({ color = "slate" }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const inputRef = useRef(null);

  //redux
  const listSearchText = useSelector(
    (state) => state.globalSlice.appliedFilterlist?.list?.searchText
  );

  //state
  const [searchText, setSearchText] = useState(listSearchText);

  useEffect(() => {
    if (location?.state?.highlightSearch) {
      inputRef.current?.focus();
    }
  }, [location]);

  useDebounce(
    () => {
      updateFilterObj({ searchText });
    },
    500,
    [searchText]
  );

  useDebounce(
    () => {
      if (!listSearchText) {
        setSearchText("");
      }
    },
    500,
    [listSearchText]
  );
  //functions
  const onClickClear = () => {
    // updateFilterObj({ searchText: "" });
    setSearchText("");
  };

  const updateFilterObj = (_obj) => {
    let obj = {
      parent_key: "list",
      obj: _obj,
    };
    dispatch(updateFilters(obj));
  };

  const onChangeSearch = (e) => {
    setSearchText(e.target.value);
    // updateFilterObj({ searchText: e.target.value });
  };

  return (
    <SearchFilterView
      inputRef={inputRef}
      active={searchText?.length > 0}
      value={searchText}
      color={color}
      onClickClear={onClickClear}
      onChange={onChangeSearch}
    />
  );
};

const DateFilter = ({ filterKey, removeFilter }) => {
  //date_type
  const dispatch = useDispatch();

  const appliedFilterlist = useSelector(
    (state) => state.globalSlice.appliedFilterlist
  );

  const updateFilterObj = (_obj) => {
    let obj = {
      parent_key: "list",
      obj: _obj,
    };
    dispatch(updateFilters(obj));
  };

  const onClickReset = (e) => {
    updateFilterObj({
      date_type: 1,
      start_date: null,
      end_date: null,
    });
    removeFilter(filterKey);
  };

  const onChangeDateFilters = (e, value) => {
    updateFilterObj({ date_type: value });
  };

  return (
    <DateRangePicker
      start_date={appliedFilterlist?.list?.start_date}
      end_date={appliedFilterlist?.list?.end_date}
      onOk={updateFilterObj}
      onClickReset={onClickReset}
      headerView={
        <Toggle
          options={dateOptions}
          value={appliedFilterlist?.list?.date_type ?? 1}
          onChange={onChangeDateFilters}
          sx={{
            ml: "9.25rem",
            mb: "0.25rem",
            "& .MuiButtonBase-root": {
              mx: "0px !important",
            },
          }}
        />
      }
      sx={{
        ml: "1rem",
      }}
    />
  );
};

const StateFilter = ({
  type = "list",
  _key = "selectedStates",
  isFilterOpen,
  optionsType,
  filterKey,
  removeFilter,
}) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.globalSlice?.state);
  const selectedStates = useSelector(
    (state) => state.globalSlice?.appliedFilterlist?.[type]?.[_key]
  );

  const options = useMemo(() => {
    let array = state;
    if (optionsType === "Open") {
      array = state?.filter(
        (o1) => !Constant?.excludeForOpenState.includes(o1?.title)
      );
    }
    if (optionsType === "Bank") {
      array = state?.filter((o1) => ["Booked", "Ignored"].includes(o1?.title));
    }

    return array;
  }, [optionsType, state]);

  const onChangeValue = (value) => {
    dispatch(updateFilters({ parent_key: type, obj: { [_key]: value } }));
  };

  const onClickReset = () => {
    onChangeValue([]);
    removeFilter(filterKey);
  };

  return (
    <ThemeFilter
      heading="state_filter"
      tooltip="tooltip_state_filter"
      icon={<TbCircles style={{ fontSize: "1rem", marginTop: "1px" }} />}
      options={options}
      translate
      selectedIds={selectedStates}
      isActive={selectedStates?.length > 0}
      onChangeValue={onChangeValue}
      onClickReset={onClickReset}
      onClear={onClickReset}
      isFilterOpen={isFilterOpen}
      maxWidth="fit-content"
    />
  );
};

const ScenarioFilter = ({
  type = "list",
  _key = "selectedScenarios",
  isFilterOpen,
  filterKey,
  removeFilter,
}) => {
  const dispatch = useDispatch();

  const scenario = useSelector((state) => state.globalSlice?.scenario);
  const selectedScenarios = useSelector(
    (state) => state.globalSlice?.appliedFilterlist?.[type]?.[_key]
  );

  const options = useMemo(() => {
    let array = [];
    scenario?.forEach((o1) => {
      if (o1.title === "Base") {
        array.push({ ...o1, translate: true });
      } else {
        array.push(o1);
      }
    });

    return array;
  }, [scenario]);

  const onChangeValue = (value) => {
    dispatch(updateFilters({ parent_key: type, obj: { [_key]: value } }));
  };

  const onClickReset = () => {
    onChangeValue([]);
    removeFilter(filterKey);
  };

  return (
    <ThemeFilter
      heading="scenario_filter"
      tooltip="tooltip_scenario_filter"
      icon={
        <TbChartAreaLineFilled style={{ fontSize: "1rem", marginTop: "1px" }} />
      }
      options={options}
      selectedIds={selectedScenarios}
      isActive={selectedScenarios?.length > 0}
      onChangeValue={onChangeValue}
      onClickReset={onClickReset}
      onClear={onClickReset}
      isFilterOpen={isFilterOpen}
      maxWidth="fit-content"
    />
  );
};

const CostUnitFilter = ({
  type = "list",
  _key = "selectedCostUnits",
  isFilterOpen,
  filterKey,
  removeFilter,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const costunits = useSelector((state) => state.globalSlice?.costunits);
  const selectedCostUnits = useSelector(
    (state) => state.globalSlice?.appliedFilterlist?.[type]?.[_key]
  );

  const onChangeValue = (value) => {
    dispatch(updateFilters({ parent_key: type, obj: { [_key]: value } }));
  };

  const onClickReset = () => {
    onChangeValue([]);
    removeFilter(filterKey);
  };

  const onClickManage = () => {
    const dataset = store?.getState().boardSlice?.dataSetData?.uuid;
    if (dataset) {
      navigate(`/${initialData.path.organization}/${dataset}/cost-units`);
    }
  };

  return (
    <ThemeFilter
      heading="costunit_filter"
      tooltip="tooltip_costunit_filter"
      icon={
        <RiFolderChartLine style={{ fontSize: "1rem", marginTop: "1px" }} />
      }
      options={costunits}
      selectedIds={selectedCostUnits}
      isActive={selectedCostUnits?.length > 0}
      onChangeValue={onChangeValue}
      onClickReset={onClickReset}
      onClear={onClickReset}
      onClickManage={onClickManage}
      isFilterOpen={isFilterOpen}
      maxWidth="fit-content"
    />
  );
};

const CategoryFilter = ({
  type = "list",
  _key = "selectedCategory",
  isFilterOpen,
  filterKey,
  removeFilter,
}) => {
  const dispatch = useDispatch();

  //redux
  const selectedCategory = useSelector(
    (state) => state.globalSlice?.appliedFilterlist?.[type]?.[_key]
  );

  //functions
  const onChangeValue = (value) => {
    dispatch(updateFilters({ parent_key: type, obj: { [_key]: value } }));
  };

  const onClickReset = () => {
    onChangeValue([]);
    removeFilter(filterKey);
  };

  return (
    <CategoryFilterView
      selectedIds={selectedCategory}
      categoryType={3}
      onClickReset={onClickReset}
      onChangeValue={onChangeValue}
      isFilterOpen={isFilterOpen}
    />
  );
};

const TypeFilter = ({
  type = "list",
  _key = "typeName",
  isFilterOpen,
  filterKey,
  removeFilter,
}) => {
  const dispatch = useDispatch();

  const typeName = useSelector(
    (state) => state.globalSlice?.appliedFilterlist?.[type]?.[_key]
  );

  const onChangeValue = (value) => {
    dispatch(updateFilters({ parent_key: type, obj: { [_key]: value } }));
  };

  const onClickReset = () => {
    onChangeValue(["Expense", "Income"]);
    removeFilter(filterKey);
  };

  return (
    <ThemeFilter
      heading="Type Filter"
      tooltip="tooltip_Type_filter"
      icon={
        <RiArrowUpDownLine style={{ fontSize: "1rem", marginTop: "2px" }} />
      }
      options={typeOptions}
      translate
      selectedIds={typeName}
      isActive={typeName?.length !== 2}
      onChangeValue={onChangeValue}
      onClickReset={onClickReset}
      onClear={onClickReset}
      isFilterOpen={isFilterOpen}
      maxWidth="fit-content"
    />
  );
};

const SourceFilter = ({
  type = "list",
  _key = "source",
  isFilterOpen,
  filterKey,
  removeFilter,
}) => {
  const dispatch = useDispatch();

  const source = useSelector(
    (state) => state.globalSlice?.appliedFilterlist?.[type]?.[_key]
  );

  const onChangeValue = (value) => {
    dispatch(updateFilters({ parent_key: type, obj: { [_key]: value } }));
  };

  const onClickReset = () => {
    onChangeValue(Constant.DefaultSource);
    removeFilter(filterKey);
  };

  return (
    <ThemeFilter
      heading="Source Filter"
      tooltip="tooltip_source_filter"
      icon={<FiLayers style={{ fontSize: "1rem", marginTop: "2px" }} />}
      options={sourceOptions}
      translate
      selectedIds={source}
      isActive={source?.length !== 4}
      onChangeValue={onChangeValue}
      onClickReset={onClickReset}
      onClear={onClickReset}
      isFilterOpen={isFilterOpen}
      maxWidth="fit-content"
    />
  );
};

const RecurringTypeFilter = ({
  type = "list",
  _key = "recurring_type",
  isFilterOpen,
  filterKey,
  removeFilter,
}) => {
  const dispatch = useDispatch();

  const recurring_type = useSelector(
    (state) => state.globalSlice?.appliedFilterlist?.[type]?.[_key]
  );

  const onChangeValue = (value) => {
    dispatch(updateFilters({ parent_key: type, obj: { [_key]: value } }));
  };

  const onClickReset = () => {
    onChangeValue([]);
    removeFilter(filterKey);
  };

  return (
    <ThemeFilter
      heading="Recurring Type Filter"
      tooltip="tooltip_recurring_type_filter"
      icon={<FiLayers style={{ fontSize: "1rem", marginTop: "2px" }} />}
      options={initialData.recurringTypeTabs}
      translate
      selectedIds={recurring_type}
      isActive={recurring_type?.length > 0}
      onChangeValue={onChangeValue}
      onClickReset={onClickReset}
      onClear={onClickReset}
      isFilterOpen={isFilterOpen}
      maxWidth="fit-content"
    />
  );
};

const DsFilter = ({
  type = "list",
  _key = "selectedDataSource",
  isFilterOpen,
  filterKey,
  removeFilter,
}) => {
  const optionsById = useRef({});
  const dispatch = useDispatch();

  //state
  const [options, setOptions] = useState([]);

  const datasetAccountList = useSelector(
    (state) => state.boardSlice.datasetAccountList
  );

  const selectedDataSource = useSelector(
    (state) => state.globalSlice?.appliedFilterlist?.[type]?.[_key]
  );
  useEffect(() => {
    let array = [];
    datasetAccountList?.forEach((o1) => {
      if (!o1?.internal_dataset && o1?.type !== 10) {
        array.push({
          uuid: o1.uuid,
          hideIcon: true,
          title: o1?.title,
        });
      }
    });
    optionsById.current = _.groupBy(array, "uuid");
    setOptions(array);
  }, [datasetAccountList]);

  //functions
  const onChangeValue = (value) => {
    dispatch(updateFilters({ parent_key: type, obj: { [_key]: value } }));
  };

  const onClickReset = () => {
    onChangeValue([]);
    removeFilter(filterKey);
  };

  return (
    <ThemeFilter
      heading="DS Filter"
      tooltip="tooltip_ds_filter"
      icon={<TbPlug style={{ fontSize: "1rem", marginTop: "1px" }} />}
      options={options}
      translate
      selectedIds={selectedDataSource}
      isActive={selectedDataSource?.length > 0}
      onChangeValue={onChangeValue}
      onClickReset={onClickReset}
      onClear={onClickReset}
      isFilterOpen={isFilterOpen}
      maxWidth="fit-content"
    />
  );
};

const ResetView = ({
  color = "slate",
  filtered,
  setIsIncludedInFilterList,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();

  const activeFilter = filtered?.length > 0;
  if (!activeFilter) {
    return null;
  }

  //functions
  const onClickReset = () => {
    setIsIncludedInFilterList([]);
    updateFilterObj({
      searchText: "",
      typeName: ["Expense", "Income"],
      source: Constant.DefaultSource,
      selectedCategory: [],
      selectedOpenStates: [],
      selectedBankStates: [],
      selectedStates: [],
      selectedScenarios: [],
      selectedCostUnits: [],
      selectedDataSource: [],
      date_type: 1,
      start_date: null,
      end_date: null,
    });
  };

  const updateFilterObj = (_obj) => {
    let obj = {
      parent_key: "list",
      obj: _obj,
    };
    dispatch(updateFilters(obj));
  };

  return (
    <Button
      onClick={onClickReset}
      sx={{
        borderRadius: 0,
        border: "none",
        textTransform: "initial",
        color: theme.palette.color[color][500],
        fontWeight: 600,
        ml: "1.5rem",
        mr: "0.5rem",
        lineHeight: "0.875rem",
        "&:hover": {
          backgroundColor: theme.palette.color[color][100],
          textDecoration: "underline",
        },
      }}
    >
      {t("reset_filters")}
    </Button>
  );
};

const BulkActionView = ({ tab }) => {
  const popupStatus5 = useSelector(
    (state) => state?.datasetSlice?.popupStatus5
  );

  if (!popupStatus5?.payload) {
    return null;
  }
  const { selectedRowData, resetKanban } = popupStatus5?.payload;
  return (
    <>
      {popupStatus5?.overlay_type === "all" &&
      popupStatus5?.payload?.selectedRowData?.length > 0 ? (
        <TransactionViewSettingPopOver
          selectedRowData={selectedRowData}
          resetKanban={resetKanban}
          tab={tab}
        />
      ) : null}
    </>
  );
};
