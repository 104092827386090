import {
  eachMonthOfInterval,
  startOfMonth,
  endOfMonth,
  addMonths,
  subMonths,
  format,
} from "date-fns";
import { useEffect, useMemo, forwardRef, useDeferredValue } from "react";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";

import {
  setMonthlyChartData,
  setTransactionData,
  setDailyChartData,
  setDataSetData,
  setDataSetList,
} from "../../../store/slices/board";
import {
  setMonthlyTransactions,
  setDailyTransactions,
  setPopupStatus,
} from "../../../store/slices/datasets";
import {
  getStatisticsDataWithParams,
  buildUrlFromParams,
  getDefaultFilters,
} from "../../../Helper/data";
import {
  setRefreshColumnData,
  setRefreshData,
} from "../../../store/slices/appmain";
import useUpdateEffect from "../../../hooks/4-useUpdateEffect/useUpdateEffect";
import { setAppliedFilterslist } from "../../../store/slices/global";
import { setCurrencyFormate } from "../../../store/slices/settings";
import useDebounce from "../../../hooks/3-useDebounce/useDebounce";
import FirstLoginOverlay from "../../Overlay/FirstLoginOverlay";
import UpdatesOverlay from "../../Overlay/UpdatesOverlay";
import EndPoints from "../../../APICall/EndPoints";
import useJoyRide from "../../../hooks/useJoyRide";
import initialData from "./../../../Helper/data";
import { queryClient } from "../../../App";
import { Constant } from "../../../Helper";
import APICall from "../../../APICall";

const CommonView = forwardRef((props, _ref) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  let abortController = new AbortController();
  let aborted = abortController.signal.aborted;

  //redux
  const dataSetList = useSelector((state) => state.boardSlice?.dataSetList);
  const state = useSelector((state) => state.globalSlice?.state);
  const refreshData = useSelector((state) => state.appSlice?.refreshData);
  const dataSource = useSelector((state) => state.globalSlice.dataSource);
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
  const isFirstLoginOverlayOpen = useSelector(
    (state) => state.globalSlice.isFirstLoginOverlayOpen
  );
  const isUpdatesOverlayOpen = useSelector(
    (state) => state.globalSlice.isUpdatesOverlayOpen
  );
  const isAccountSelectOverlayOpen = useSelector(
    (state) => state.globalSlice.isAccountSelectOverlayOpen
  );
  const isAllHeaderApiFetched = useSelector(
    (state) => state.commonSlice.isAllHeaderApiFetched
  );
  const joyRideStatus = useSelector(
    (state) => state.globalSlice?.joyRideStatus
  );

  const refreshColumnData = useSelector(
    (state) => state.appSlice?.refreshColumnData
  );
  const appliedFilterlist = useSelector(
    (state) => state.globalSlice.appliedFilterlist
  );

  const is_screen_inside_tab = useMemo(() => {
    return (
      location?.pathname.includes(`/${initialData?.path?.organization}/`) &&
      !location?.pathname.includes("/settings/")
    );
  }, [location?.pathname]);

  const allow_Monthly_statistics = useMemo(() => {
    return (
      !location?.pathname.includes("/reports/") &&
      !location?.pathname.includes("/list/")
    );
  }, [location?.pathname]);

  const allowCheck =
    isAllHeaderApiFetched &&
    !isAccountSelectOverlayOpen &&
    !isFirstLoginOverlayOpen &&
    !location.pathname?.includes("/callback") &&
    !location.pathname?.includes("client_id") &&
    !location.pathname?.includes("error") &&
    !location.pathname?.includes("code") &&
    location.pathname !== "/";

  // const allow_Daily_statistics = useMemo(() => {
  //   return (
  //     location?.pathname.includes("/reports/balance-sheet") &&
  //     !location?.pathname.includes("/reports/revenue/recurring-income") &&
  //     !location?.pathname.includes("/reports/customers/") &&
  //     !location?.pathname.includes("/reports/suppliers/") &&
  //     !location?.pathname.includes("/list/")
  //   );
  // }, [location?.pathname]);

  //api
  const updateDataSetByID = async (id, obj) => {
    await APICall("patch", EndPoints.datasets + `${id}/`, obj).then(
      (response) => {
        if (response.status === 200 && response.data && aborted === false) {
          let data = [...dataSetList];
          const index = data?.findIndex((o1) => o1.uuid === id);
          if (index > -1) {
            data[index] = response.data;
            dispatch(setDataSetList(data));
            dispatch(setDataSetData(response.data));
            dispatch(setAppliedFilterslist(response?.data?.filters));
          }
        }
      }
    );
  };

  const getContacts = async (params) => {
    let result = null;
    await APICall("get", EndPoints.customers + buildUrlFromParams(params)).then(
      (response) => {
        if (response.status === 200 && response.data) {
          result = response.data.results;
        }
      }
    );
    return result;
  };

  const _from_payment_date = startOfMonth(
    subMonths(new Date(), Constant.Past_Month)
  );
  const _to_payment_date = endOfMonth(
    subMonths(new Date(dataSetData?.start_date), 7)
  );
  const from_payment_date = useDeferredValue(_from_payment_date);
  const to_payment_date = useDeferredValue(_to_payment_date);
  const dataset = useDeferredValue(dataSetData?.uuid);

  const multiStatesIds = useMemo(() => {
    return state
      ?.filter((o1) => !Constant.calculationExcludeStates.includes(o1?.title))
      ?.map((o1) => o1?.uuid);
  }, [state]);
  const Transaction_monthly_statistic = useQuery({
    queryKey: [
      "transactions",
      {
        dataset: dataset,
        apiType: "monthly",
        parentApiType: "Transaction_monthly_statistic",
        from_payment_date,
        to_payment_date,
      },
    ],
    queryFn: () => {
      const result = getStatisticsDataWithParams({
        type: "monthly",
        dataset: dataset,
        from_payment_date,
        to_payment_date,
        multiStatesIds,
      });
      if (result) {
        return result;
      }
    },
    backgroundFetch: true,
    enabled:
      isAllHeaderApiFetched &&
      !isFirstLoginOverlayOpen &&
      !!is_screen_inside_tab &&
      !!allow_Monthly_statistics &&
      !!_from_payment_date &&
      _from_payment_date <= _to_payment_date &&
      !!to_payment_date &&
      !!dataset &&
      !!dataSource &&
      !isFirstLoginOverlayOpen,
    priority: 3,
  });

  //
  const Staff = useQuery({
    queryKey: ["staff"],
    queryFn: ({ signal }) => {
      const result = getContacts({
        type: 3,
      });
      if (result) {
        return result;
      }
    },
    backgroundFetch: true,
    refetchOnMount: true,
    priority: 1,
    cacheTime: 10000,
    enabled:
      isAllHeaderApiFetched &&
      !isFirstLoginOverlayOpen &&
      !!is_screen_inside_tab &&
      !!dataset &&
      !!dataSource,
  });

  if (
    _from_payment_date > _to_payment_date &&
    Transaction_monthly_statistic?.data
  ) {
    queryClient.removeQueries({
      queryKey: [
        "transactions",
        {
          dataset: dataset,
          apiType: "monthly",
          parentApiType: "Transaction_monthly_statistic",
          from_payment_date,
          to_payment_date,
        },
      ],
    });
  }

  let fetching_monthly_statistic =
    Transaction_monthly_statistic.isFetching ||
    Transaction_monthly_statistic.isLoading ||
    !Transaction_monthly_statistic.isSuccess;

  //lifecycle method
  useEffect(() => {
    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    if (dataSetData?.uuid && allowCheck) {
      let array = location.pathname?.split("/");
      array.splice(0, 3);
      if (array.length !== 0) {
        localStorage.setItem("last_visited_dataset", dataSetData?.uuid);
        let url = `/${initialData?.path?.organization}/${dataSetData?.uuid}`;
        array.forEach((path) => {
          url = url + "/" + path;
        });
        navigate(url);
        let obj;
        if (dataSetData?.currency === "EUR") {
          obj = {
            disableGroupSeparators: false,
            prefix: "€",
            groupSeparator: ".",
            decimalSeparator: ",",
          };
        } else {
          obj = {
            disableGroupSeparators: false,
            prefix: "$",
            groupSeparator: ",",
            decimalSeparator: ".",
          };
        }
        const data = dataSetList?.find((o1) => {
          return o1.uuid === dataSetData?.uuid;
        });
        global.allowFetch = { Inflow: [], Outflow: [] };
        dispatch(setAppliedFilterslist({ ...data?.filters }));
        dispatch(setCurrencyFormate(obj));
        dispatch(setMonthlyChartData([]));
        dispatch(setDailyChartData([]));
        dispatch(setDailyTransactions([]));
        dispatch(setMonthlyTransactions([]));
        dispatch(setTransactionData([]));
        dispatch(
          setPopupStatus({
            open: false,
            anchorEl: null,
          })
        );
      }
    }
  }, [allowCheck, dataSetData?.uuid]);

  useEffect(() => {
    if (
      !location?.pathname?.includes(`/${initialData?.path?.organization}`) &&
      dataSetData
    ) {
      dispatch(setDataSetData(null));
    }
  }, [dataSetData, location.pathname]);

  useDebounce(
    () => {
      if (
        location?.pathname.includes(`/${initialData?.path?.organization}/`) &&
        allowCheck &&
        dataSetList?.length > 0
      ) {
        const myArray = location?.pathname.split("/");
        const datasetId = myArray[2];

        if (
          state &&
          (!appliedFilterlist ||
            !dataSetData ||
            (dataSetData && datasetId && dataSetData?.uuid !== datasetId))
        ) {
          const data = dataSetList?.find((o1) => o1.uuid === datasetId);
          if (data) {
            localStorage.setItem("last_visited_dataset", data?.uuid);
            if (
              Boolean(JSON.stringify(data?.filters) === "{}") ||
              !data?.start_date ||
              !data?.end_date ||
              (data?.filters &&
                (!data?.filters?.list ||
                  !data?.filters?.list?.selectedBankStates ||
                  !data?.filters?.list?.source))
            ) {
              const filters = getDefaultFilters();
              let obj = { filters };

              if (!data?.start_date || !data?.end_date) {
                const startDate = startOfMonth(subMonths(new Date(), 1));
                const endDate = endOfMonth(
                  addMonths(startDate, Constant.column_count)
                );
                const start_date = format(new Date(startDate), "yyyy-MM-dd");
                const end_date = format(new Date(endDate), "yyyy-MM-dd");
                obj.start_date = start_date;
                obj.end_date = end_date;
              }
              updateDataSetByID(data?.uuid, obj);
            } else {
              dispatch(setAppliedFilterslist({ ...data?.filters }));
              dispatch(setDataSetData(data));
            }
          } else {
            if (
              location.pathname?.includes(
                `/${initialData?.path?.organization}/`
              )
            ) {
              navigate("/dashboard");
            }
          }
        }
      }
    },
    500,
    [location?.pathname, allowCheck, dataSetList],
    true
  );

  //updateMonthlyState
  useDebounce(
    () => {
      if (!fetching_monthly_statistic) {
        let data = Transaction_monthly_statistic?.data?.results || [];
        dispatch(setMonthlyTransactions([...data]));
      }
    },
    300,
    [fetching_monthly_statistic, Transaction_monthly_statistic?.data],
    true
  );

  useUpdateEffect(() => {
    if (refreshData) {
      refreshQuery();
    }
  }, [refreshData]);

  useUpdateEffect(() => {
    if (refreshColumnData) {
      refreshColumnQuery();
    }
  }, [refreshColumnData]);

  useJoyRide({
    key: "dataset_header",
    allow: dataSetData?.uuid && allowCheck,
  });

  useJoyRide({
    key: "reports",
    allow:
      dataSetData?.uuid &&
      allowCheck &&
      !joyRideStatus?.filters?.reports &&
      location.pathname?.includes("/reports/overview"),
    //  &&!tourState?.run,
  });

  //function

  const refreshQuery = async () => {
    let options = {
      predicate: (query) =>
        query.queryKey[0] === "transactions" &&
        query.queryKey[1]?.dataset === dataSetData?.uuid &&
        refreshData?.key?.includes(query.queryKey[1]?.apiType),
      type: "active",
      ...refreshData?.filters,
    };
    refresh(options);
    dispatch(setRefreshData(null));
  };

  const refreshColumnQuery = async () => {
    if (!refreshColumnData?.date) {
      let options = {
        predicate: (query) =>
          query.queryKey[0] === "transactions" &&
          query.queryKey[1]?.apiType === "normal_monthly_transactions" &&
          query.queryKey[1]?.dataset === dataSetData?.uuid &&
          query.queryKey[1]?.start_date === dataSetData?.start_date &&
          (refreshColumnData?.boardType
            ? refreshColumnData?.boardType?.includes(
                query.queryKey[1]?.boardType
              )
            : true),
        ...refreshColumnData?.filters,
      };

      refresh(options);
    } else {
      let dates = [...new Set(refreshColumnData?.date)]?.map((o1) =>
        format(startOfMonth(new Date(o1)), "yyyy-MM-dd")
      );
      let boardType = [refreshColumnData?.boardType];
      if (
        refreshColumnData?.cardItem &&
        Number(refreshColumnData?.cardItem?.gross) === 0 &&
        !boardType.includes("Inflow")
      ) {
        boardType.push("Inflow");
      }

      boardType.forEach((element) => {
        global.allowFetch = {
          [element]: dates,
        };
      });

      let options = {
        predicate: (query) =>
          query.queryKey[0] === "transactions" &&
          query.queryKey[1]?.apiType === "Kanban_Transactions" &&
          query.queryKey[1]?.dataset === dataSetData?.uuid &&
          (boardType
            ? boardType?.includes(query.queryKey[1]?.boardType)
            : true) &&
          (dates ? dates?.includes(query.queryKey[1]?.date) : true),
        // type: "active",
        ...refreshColumnData?.filters,
      };
      refresh(options);
    }

    setTimeout(() => {
      global.allowFetch = {
        Inflow: [],
        Outflow: [],
      };
    }, 6000);
  };

  const refresh = (options) => {
    // queryClient.refetchQueries(options);
    queryClient.resetQueries(options);
    queryClient.invalidateQueries(options, {
      cancelRefetch: true,
    });
    dispatch(setRefreshColumnData(null));
  };

  return (
    <>
      {isFirstLoginOverlayOpen && <FirstLoginOverlay />}
      {isUpdatesOverlayOpen && <UpdatesOverlay />}
    </>
  );
});
export default CommonView;
