import {
  AccordionDetails,
  AccordionSummary,
  Typography,
  Accordion,
  useTheme,
  Divider,
  Tooltip,
  Button,
  Stack,
  alpha,
  Box,
} from "@mui/material";
import TipsAndUpdatesTwoToneIcon from "@mui/icons-material/TipsAndUpdatesTwoTone";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useTranslation } from "react-i18next";
import { differenceInDays } from "date-fns";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import * as React from "react";

import IntegrationsDialogView from "../Header/DatasetHeader/IntegrationsDialogView";
import DSConnections from "../../Pages/Settings/DataSource/DSConnections";
import { setIsOnBoardingOverlayOpen } from "../../store/slices/common";
import { isPlanExpired, thinScrollbarStyle } from "../../Helper/data";
import HeaderIconView from "../Header/Component/HeaderIconView";
import { GlobalContext } from "../../GlobalContextWrapper";
import CalendlyOverlay from "../Overlay/CalendalyOverlay";
import { setDataSource } from "../../store/slices/global";
import OnBoardingFunctions from "./OnBoardingFunctions";
import Translate from "../../hooks/HOC/Translate";
import EndPoints from "../../APICall/EndPoints";
import CustomPopover from "../PopOver";
import { Color } from "../../Helper";
import APICall from "../../APICall";

const OnbButtonView = React.memo((props) => {
  const viewRef = React.useRef(null);
  const theme = useTheme();
  const isOpenFirstTime = React.useRef(false);
  const first_login = useSelector(
    (state) => state.settingsSlice?.profile?.first_login
  );
  const onBoardingList = useSelector(
    (state) => state?.globalSlice?.onBoardingList
  );

  React.useEffect(() => {
    if (first_login && !isOpenFirstTime.current) {
      isOpenFirstTime.current = true;
      props?.popup_state?.open(viewRef.current);
    }
  }, [first_login, props?.popup_state]);
  const completedTasks = React.useMemo(
    () => onBoardingList?.filter((task) => task?.checked).length,
    [onBoardingList]
  );
  const remaining_step = onBoardingList?.length - completedTasks;

  return (
    <HeaderIconView
      tooltip=""
      icon={
        <Box
          ref={viewRef}
          sx={{
            display: "flex",
            alignItems: "center",
            mx: "0.5rem",
          }}
        >
          <CircularSegmentedProgressBar
            tasks={onBoardingList}
            completedTasks={completedTasks}
          />

          <Tooltip
            title={
              <Translate
                i18nkey={"Onboarding_steps"}
                values={{ remaining_step: remaining_step || 0 }}
              />
            }
          >
            <Typography
              variant="h6"
              mx="0.5rem"
              fontWeight={"fontWeightMediumBold"}
              sx={{
                fontSize: { xs: "0.65rem", small: "0.9rem" },
                lineHeight: { xs: "0.65rem", small: "1rem" },
                color: theme.palette.color.white,
              }}
            >
              <Translate i18nKey={"Quick Start"} />
            </Typography>
          </Tooltip>
        </Box>
      }
      sx={{
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
          backgroundColor: alpha(theme.palette.primary.main, 0.9),
        },
      }}
    />
  );
});

export default function OnBoardingStepper({ isStartPage }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const btnRef = React.useRef(null);
  const dsRef = React.useRef(null);
  const onbRef = React.useRef();

  //state
  const isDSChanged = React.useRef(false);
  const [isOpen, setIsOpen] = React.useState(null);
  const [expanded, setExpanded] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const onBoardingList = useSelector(
    (state) => state?.globalSlice?.onBoardingList
  );

  const first_name = useSelector(
    (state) => state.settingsSlice?.profile?.first_name
  );
  const locale = useSelector((state) => state.settingsSlice?.profile?.locale);

  const isOnBoardingOverlayOpen = useSelector(
    (state) => state.commonSlice?.isOnBoardingOverlayOpen
  );

  const isEnglishLanguage = locale !== "de_DE";

  React.useEffect(() => {
    setExpanded(onBoardingList?.[0]?.uuid);
  }, []);

  React.useEffect(() => {
    if (isOnBoardingOverlayOpen) {
      onClickPopOver();
    } else {
      onClosePopOver();
    }
  }, [isOnBoardingOverlayOpen]);

  //api
  const getDataSource = async () => {
    await APICall("get", EndPoints.integrations).then((response) => {
      if (response.status === 200 && response.data) {
        const data = response.data.results;
        dispatch(setDataSource(data));
      }
    });
  };

  //functions
  const onClickPopOver = () => {
    setAnchorEl(btnRef.current);
    if (!isOnBoardingOverlayOpen) {
      dispatch(setIsOnBoardingOverlayOpen(true));
    }
  };

  const onClosePopOver = () => {
    if (Boolean(anchorEl)) {
      setAnchorEl(null);
      dispatch(setIsOnBoardingOverlayOpen(false));
    }
  };

  const handleClickClose = () => {
    setIsOpen(false);
    if (isDSChanged.current) {
      getDataSource();
    }
  };

  return (
    <>
      <DSConnections ref={dsRef} />
      {Boolean(isOpen) ? (
        <IntegrationsDialogView
          open={Boolean(isOpen)}
          type={isOpen}
          handleClickClose={handleClickClose}
          isDSChanged={isDSChanged}
        />
      ) : null}
      <CustomPopover
        type="anchor"
        ref={btnRef}
        anchorEl={anchorEl}
        onClose={onClosePopOver}
        onClick={onClickPopOver}
        button={<OnbButtonView isStartPage={isStartPage} />}
        popupProps={{
          anchorReference: "anchorPosition",
          anchorPosition: { top: 0, left: 0 },
        }}
        sxStyle={{ mt: 0 }}
        paperStyle={{
          backgroundColor: "transparent",
          maxHeight: "100%",
          height: "100%",
          left: "unset !important",
          right: 0,
          top: "0px !important",
          borderRadius: 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "30rem",
            height: "99.95vh",
            position: "relative",
            backgroundImage: `linear-gradient(0deg, ${theme.palette.primary[500]} 0%, ${theme.palette.primary.dark} 100%)`,
            ...theme.thinScrollBar,
          }}
        >
          <Stack p="2rem">
            <Typography
              variant="h6"
              sx={{
                fontWeight: 600,
                fontSize: "1.1rem",
                color: Color.white,
              }}
            >
              <Translate
                i18nkey={"main_header_onboarding_overlay_msg"}
                values={{ name: first_name }}
              />
            </Typography>
          </Stack>
          <Box
            height={"100%"}
            display={"flex"}
            flexDirection={"column"}
            overflow={"auto"}
            sx={{
              ...thinScrollbarStyle,
              "& .MuiAccordion-root:Before": {
                display: "none",
              },
            }}
          >
            <OnBoardingFunctions
              ref={onbRef}
              dsRef={dsRef}
              setIsOpen={setIsOpen}
            />
            {onBoardingList?.map((item, index) => (
              <RootItem
                key={item?.uuid}
                item={item}
                expanded={expanded === item?.uuid}
                setExpanded={setExpanded}
                onbRef={onbRef}
                parentIndex={index}
                isEnglishLanguage={isEnglishLanguage}
              />
            ))}
          </Box>
          <SubscribeView />
        </Box>
      </CustomPopover>
    </>
  );
}

const RootItem = ({
  expanded,
  setExpanded,
  item,
  onbRef,
  parentIndex,
  isEnglishLanguage,
}) => {
  const onChange = () => {
    setExpanded(expanded ? false : item?.uuid);
  };

  return (
    <Accordion
      elevation={0}
      disableGutters
      expanded={expanded}
      onChange={onChange}
      sx={{
        px: "2rem",
        border: "none",
        backgroundColor: "transparent",
        boxShadow: "none !important",
      }}
    >
      <AccordionSummary
        expandIcon={
          <ArrowForwardIosSharpIcon
            sx={{ fontSize: "1rem", color: Color.white }}
          />
        }
        aria-controls={`${item?.uuid}-content`}
        id={`${item?.uuid}-header`}
        sx={{
          flexDirection: "row-reverse",
          "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
            transform: "rotate(90deg)",
          },
          "& .MuiAccordionSummary-content": {
            marginLeft: "0.5rem",
          },
          "& p": {
            marginBlock: "0px !important",
          },
        }}
      >
        <Typography
          sx={{
            flexShrink: 0,
            color: Color.white,
            fontSize: "1rem",
            display: "inline-flex",
            textDecoration: item?.checked ? "line-through" : "none",
          }}
        >
          {isEnglishLanguage ? item?.title_en : item?.title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          borderRadius: 2,
          backgroundColor: Color.white,
          p: "1.5rem",
          mx: "0.75rem",
        }}
      >
        {item?.children?.map((subItem, index) => {
          const activeBtn = !subItem?.checked;

          return (
            <SubItem
              key={subItem?.uuid}
              subItem={subItem}
              isLastItem={item?.children?.length - 1 === index}
              activeBtn={activeBtn}
              onbRef={onbRef}
              parentIndex={parentIndex}
              isEnglishLanguage={isEnglishLanguage}
            />
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
};

const SubItem = ({
  subItem,
  isLastItem,
  activeBtn,
  onbRef,
  parentIndex,
  isEnglishLanguage,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const globalContext = React.useContext(GlobalContext);

  const finbanInfoRef = globalContext?.finbanInfoRef;
  const contentRef = React.useRef(null);
  const [showMore, setShowMore] = React.useState(false);
  const [isOverflowing, setIsOverflowing] = React.useState(false);

  const displayContent = isEnglishLanguage
    ? subItem?.content_en
    : subItem?.content;
  const handleToggleShowMore = () => {
    setShowMore((prev) => !prev);
  };

  const onClickLink = () => {
    finbanInfoRef.current?.open({
      url: subItem?.more_information_link,
    });
  };

  const measureContent = () => {
    if (contentRef.current) {
      const element = contentRef.current;
      const lineHeight = parseFloat(getComputedStyle(element).lineHeight);
      const maxHeight = lineHeight * 3;
      const actualHeight = element.scrollHeight;

      if (actualHeight > maxHeight) {
        setIsOverflowing(true);
      } else {
        setIsOverflowing(false);
      }
    }
  };

  React.useEffect(() => {
    setTimeout(measureContent, 100);
  }, [displayContent]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography
        sx={{
          flexShrink: 0,
          color: theme.palette.primary.main,
          fontWeight: 600,
          fontSize: "1rem",
          display: "inline-flex",
          textDecoration: subItem?.checked ? "line-through" : "none",
        }}
      >
        <CheckCircleIcon
          sx={{
            color: subItem?.checked
              ? Color.tailwind.green[500]
              : Color.tailwind.slate[200],
            mr: "0.75rem",
          }}
        />
        {isEnglishLanguage ? subItem?.title_en : subItem?.title}
      </Typography>
      <Typography
        ref={contentRef}
        dangerouslySetInnerHTML={{
          __html: displayContent,
        }}
        color="color.description"
        sx={{
          ml: "2.25rem",
          fontSize: "0.9rem",
          lineHeight: "1.25rem",
          my: 0,
          overflow: "hidden",
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: showMore ? "unset" : 3,
          transition: "all 0.3s ease",
        }}
      ></Typography>
      {isOverflowing && (
        <Button
          size="small"
          onClick={handleToggleShowMore}
          sx={{ width: "fit-content", ml: "2rem", fontWeight: 600 }}
        >
          {showMore ? "Show Less" : "Show More"}
        </Button>
      )}
      {subItem?.more_information_link ? (
        <Typography
          onClick={onClickLink}
          direction="row"
          alignItems="center"
          sx={{
            ml: "2.35rem",
            cursor: "pointer",
            color: theme.palette.primary.main,
            fontSize: "0.85rem",
            mt: "0.5rem",
            "&:hover": {
              textDecoration: "underline",
            },
          }}
        >
          {t("More information for this topic")}
        </Typography>
      ) : null}
      <ButtonView
        subItem={subItem}
        activeBtn={activeBtn}
        onbRef={onbRef}
        parentIndex={parentIndex}
        isEnglishLanguage={isEnglishLanguage}
      />

      {isLastItem ? null : <Divider sx={{ my: "1rem" }} />}
    </Box>
  );
};

const ButtonView = ({
  subItem,
  activeBtn = false,
  onbRef,
  isEnglishLanguage,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isNoLink = !!subItem?.more_information_link;

  const onClickActivate = (e) => {
    e.stopPropagation();
    onbRef.current?.updateOnboardingList({
      uuid: subItem?.uuid,
      payload: {
        checked: false,
      },
    });
  };

  const onClickDismiss = (e) => {
    e.stopPropagation();
    onbRef.current?.updateOnboardingList({
      uuid: subItem?.uuid,
      payload: {
        checked: true,
      },
    });
  };

  const onClickAdd = () => {
    onbRef.current?.onClickAction(subItem);
  };

  return (
    <Stack ml={activeBtn ? "2.25rem" : "1.85rem"} mt="0.5rem">
      {activeBtn ? (
        <Stack direction="row" alignItems={"center"}>
          <Button
            onClick={onClickAdd}
            variant={isNoLink ? "outlined" : "contained"}
            sx={{
              backgroundColor: isNoLink
                ? "transparent"
                : theme.palette.primary.dark,
              color: isNoLink ? Color.tailwind.slate[600] : Color.white,
              py: "0.75rem",
              px: "1rem",
              fontSize: "0.8rem",
              lineHeight: "0.8rem",
              borderRadius: 2,
              display: "inline-flex",
              textTransform: "initial",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              "&:hover": {
                backgroundColor: isNoLink
                  ? "transparent"
                  : theme.palette.primary.darkHover,
              },
            }}
          >
            {isEnglishLanguage
              ? subItem?.button_title_en
              : subItem?.button_title_de}
          </Button>
          <Button
            onClick={onClickDismiss}
            variant="text"
            sx={{
              width: "fit-content",
              color: Color.tailwind.slate[500],
              py: "0.75rem",
              px: "1rem",
              ml: "1rem",
              fontSize: "0.8rem",
              lineHeight: "0.8rem",
              borderRadius: 2,
              display: "inline-flex",
              textTransform: "initial",
            }}
          >
            {t("Skip")}
          </Button>
        </Stack>
      ) : (
        <Button
          onClick={onClickActivate}
          variant="text"
          sx={{
            width: "fit-content",
            color: Color.tailwind.slate[500],
            py: "0.75rem",
            px: "0.5rem",
            fontSize: "0.9rem",
            lineHeight: "0.9rem",
            borderRadius: 2,
            display: "inline-flex",
            textTransform: "initial",
          }}
        >
          {t("Reactivate")}
        </Button>
      )}
    </Stack>
  );
};

const SubscribeView = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const calendlyRef = React.useRef();

  const subscription = useSelector(
    (state) => state.settingsSlice?.subscription
  );
  const day = differenceInDays(
    new Date(subscription?.expiry_date ?? new Date()),
    new Date()
  );

  const getText = () => {
    let text = "";
    if (isPlanExpired(subscription)) {
      text = t("Your subscription plan  Expired - Please upgrade you plan.", {
        subscription: subscription?.name,
      });
      return text;
    }
    if (subscription?.name === "Trial") {
      if (day >= 0 && day <= 2) {
        text = t(
          "Your subscription plan will Expire, Book now for a 25% discount.",
          {
            subscription: subscription?.name,
            day: day + 1,
            day_plural: day > 0 ? t("day_s") : t("day"),
          }
        );
        return text;
      }
      text = t("You can still test finban, Book now for a 25% discount.", {
        subscription: subscription?.name,
        day: day + 1,
        day_plural: day > 0 ? t("day_s") : t("day"),
      });
    } else {
      if (day >= 0 && day <= 2) {
        text = t("Your subscription plan will Expired in day", {
          subscription: subscription?.name,
          day: day + 1,
          day_plural: day > 0 ? t("day_s") : t("day"),
        });
        return text;
      }
    }
    return text;
  };

  const onSubscribe = () => {
    navigate("/settings/subscription");
  };

  const onClickCalendly = () => {
    calendlyRef.current?.handleOpen();
  };

  const subscriptionText = React.useMemo(() => {
    return getText();
  }, [subscription]);

  return (
    <Stack
      direction={"column"}
      backgroundColor={theme.palette.primary[600]}
      p="2rem"
      mt="2rem"
    >
      <CalendlyOverlay ref={calendlyRef} />

      {subscriptionText ? (
        <>
          <Typography
            sx={{
              flexShrink: 0,
              color: Color.white,
              fontWeight: 700,
              fontSize: "1rem",
              display: "inline-flex",
            }}
          >
            {subscriptionText}
          </Typography>
          <Button
            onClick={onSubscribe}
            sx={{
              backgroundColor: Color.tailwind.blue[600],
              color: Color.white,
              py: "0.75rem",
              px: "2rem",
              my: "1rem",
              fontSize: "0.9rem",
              lineHeight: "0.9rem",
              borderRadius: 2,
              display: "inline-flex",
              textTransform: "initial",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              "&:hover": {
                backgroundColor: Color.tailwind.blue[600],
              },
            }}
          >
            {t("Subscribe now")}
          </Button>
        </>
      ) : null}
      <Typography
        sx={{
          flexShrink: 0,
          color: Color.white,
          fontWeight: 700,
          fontSize: "1rem",
          display: "inline-flex",
        }}
      >
        {t("Need help?")}
      </Typography>
      <Button
        onClick={onClickCalendly}
        sx={{
          backgroundColor: theme.palette.primary.dark,
          color: theme.palette.color.white,
          border: `1px solid ${theme.palette.color.white}`,
          py: "0.75rem",
          px: "1rem",
          mt: "1rem",
          fontSize: "0.9rem",
          lineHeight: "0.9rem",
          borderRadius: 2,
          display: "inline-flex",
          textTransform: "initial",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          width: "fit-content",
          "&:hover": {
            backgroundColor: alpha(theme.palette.color.white, 0.1),
          },
        }}
      >
        {t("Book 10-min intro call")}
      </Button>
    </Stack>
  );
};

// Utility function to calculate the coordinates for each segment
const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
  const angleInRadians = (angleInDegrees - 90) * (Math.PI / 180);
  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
};

const describeArc = (x, y, radius, startAngle, endAngle) => {
  const start = polarToCartesian(x, y, radius, endAngle);
  const end = polarToCartesian(x, y, radius, startAngle);
  const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

  const d = [
    "M",
    start.x,
    start.y,
    "A",
    radius,
    radius,
    0,
    largeArcFlag,
    0,
    end.x,
    end.y,
  ].join(" ");

  return d;
};

const CircularSegmentedProgressBar = ({ tasks = [], completedTasks }) => {
  const theme = useTheme();
  const radius = 12; // Circle radius
  const segmentAngle = 360 / tasks.length; // Angle per segment
  const center = 12; // SVG center
  const gapSize = 6; // Adjust this value for bigger gaps
  return (
    <svg
      width="30"
      height="30"
      viewBox={`0 0 ${center * 2} ${center * 2}`} // update center based on this
      style={{ overflow: "visible", marginLeft: "-0.4rem" }}
    >
      {tasks?.map((task, index) => {
        const startAngle = index * segmentAngle;
        const endAngle = startAngle + segmentAngle - gapSize;

        return (
          <path
            key={task?.uuid}
            d={describeArc(center, center, radius, startAngle, endAngle)}
            fill="none"
            stroke={
              task.checked
                ? theme.palette.color.white
                : theme.palette.color.slate[400]
            }
            strokeWidth="2"
          />
        );
      })}
      <text
        x="50%"
        y="54%"
        dominantBaseline="middle"
        textAnchor="middle"
        fontSize="7"
        fontFamily={theme.typography.fontFamily}
        fill={theme.palette.color.white}
      >
        {`${completedTasks}/${tasks.length}`}
      </text>
    </svg>
  );
};
